import "../../../App.css";
import "../../../sass/style.css";
import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import ObtUsuarioRemaxComponent from "../../../components/Funciones";
import { TablaClientes } from "../../../components/Tablas";
import { get, put } from "../../../utils/http.js";
import { ModalInicioSocios } from "../../../components/Modals.jsx";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer} from 'recharts';

function GraficaInvitaciones({ estadisticas = null, noInvitaciones = 0}){

  const [estadisticasInvitaciones, setEstadisticasInvitaciones] = useState([{}]);
  const [esqueleto, setEsqueleto] = useState(true);
  const [barSize, setBarSize] = useState(40);
  const [sinInvitaciones, setSinInvitaciones] = useState(false);

  useEffect(() => {
    const updateBarSize = () => {
      setBarSize(window.innerWidth < 600 ? 25 : 40);
    };

    window.addEventListener('resize', updateBarSize);
    updateBarSize();

    return () => window.removeEventListener('resize', updateBarSize);
  }, []);

  useEffect(() => {
    if(estadisticas !== null){
      if (noInvitaciones < 1){
        setEstadisticasInvitaciones([{
          documentacionAcreditados: 1,
          firmaSolicitud: 2,
          respuestaBanco: 3,
          vivienda: 4,
          avaluo: 5,
          notaria: 9,
          formalizacion: 7,
        }]);
        setSinInvitaciones(true);
      }else{
        setEstadisticasInvitaciones([estadisticas]);
        setEsqueleto(false);
      }
    }else{
      setEstadisticasInvitaciones([{
        documentacionAcreditados: 0,
        firmaSolicitud: 0,
        respuestaBanco: 0,
        vivienda: 0,
        avaluo: 0,
        notaria: 0,
        formalizacion: 0,
      }]);
    }
  }, [estadisticas, noInvitaciones]);


  return (
    <>
      <div id="cont_grafica_invitaciones_bancos">
        <div id="contenedor-invitaciones-bancos" className="height200px">
          <ResponsiveContainer width="100%" height="100%">
            {
              esqueleto ? (
                <BarChart
                  data={estadisticasInvitaciones}
                  margin={{
                    top: 20,
                    bottom: 5,
                  }}
                  barSize={barSize}
                  barGap={7}
                >
                  <XAxis dataKey="titulo" hide={true}/>
                  <YAxis hide={true} type="number" domain={[0, 'dataMax + 1']} />
                  <Bar name="Documentación Acreditados" dataKey="documentacionAcreditados" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Firma de Solicitud" dataKey="firmaSolicitud" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Respuesta de Banco" dataKey="respuestaBanco" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Documentación Vivienda" dataKey="vivienda" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Avalúo" dataKey="avaluo" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Notaría" dataKey="notaria" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Formalización" dataKey="formalizacion" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                </BarChart>
              ) : (
                <BarChart
                  data={estadisticasInvitaciones}
                  margin={{
                    top: 20,
                    bottom: 5,
                  }}
                  barSize={barSize}
                  barGap={7}
                >
                  <XAxis dataKey="titulo" hide={true}/>
                  <YAxis hide={true} type="number" domain={[0, 'dataMax + 1']} />
                  <Bar name="Documentación Acreditados" dataKey="documentacionAcreditados" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#4BBB7A" isAnimationActive={false}/>
                  <Bar name="Firma de Solicitud" dataKey="firmaSolicitud" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#1ACAD8" isAnimationActive={false}/>
                  <Bar name="Respuesta de Banco" dataKey="respuestaBanco" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#C5EEA9" isAnimationActive={false}/>
                  <Bar name="Documentación Vivienda" dataKey="vivienda" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#4B75BB" isAnimationActive={false}/>
                  <Bar name="Avalúo" dataKey="avaluo" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#9C36B2" isAnimationActive={false}/>
                  <Bar name="Notaría" dataKey="notaria" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#BDC83E" isAnimationActive={false}/>
                  <Bar name="Formalización" dataKey="formalizacion" label={{fill: "#000", position: "top", formatter: (valor) => valor ? valor : ""}} background={{ fill: '#eee' }} fill="#91937D" isAnimationActive={false}/>
                </BarChart>
              )
            }
          </ResponsiveContainer>
        </div>
        <div className="height200px">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              margin={{
                right: 20,
                left: 20,
              }} 
              data={estadisticasInvitaciones}
            >
              <Bar name="Documentación Acreditados" fill={esqueleto ? "#666666" : "#4BBB7A"}/>
              <Bar name="Firma de Solicitud" fill={esqueleto ? "#666666" : "#1ACAD8"}/>
              <Bar name="Respuesta de Banco" fill={esqueleto ? "#666666" : "#C5EEA9"}/>
              <Bar name="Documentación Vivienda" fill={esqueleto ? "#666666" : "#4B75BB"}/>
              <Bar name="Avalúo" fill={esqueleto ? "#666666" : "#9C36B2"}/>
              <Bar name="Notaría" fill={esqueleto ? "#666666" : "#BDC83E"}/>
              <Bar name="Formalización" fill={esqueleto ? "#666666" : "#91937D"}/>

              <Legend formatter={(value, entry, index) => <span className="fuente_secundaria leyenda">{value}</span>} layout="vertical" align="left" verticalAlign="middle" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      {
        sinInvitaciones && (
          <p className="margin0">
            ¡Bienvenido! Aquí verás tus métricas personalizadas en cuanto empieces a interactuar con nuestro servicio.
          </p>
        )
      }
    </>
    
  );
}

export default function PanelSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const idSocios = cookies.get("idSocio");
  const [clientes, setClientes] = useState([]);
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);
  const [solicitudesCredito, setSolicitudesCredito] = useState(undefined);

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  async function obtInfoClientes(){
    const peticionClientes = await get("obtClientesSocio", idSocios);
    const peticionEstatusInvitaciones = await get("obtEstatusSolicitudCreditoInvitaciones", idSocios);

    if(peticionClientes.statuscode === 200){
      setClientes(peticionClientes.respuesta);
    }

    if(peticionEstatusInvitaciones.statuscode === 200){
      setSolicitudesCredito(peticionEstatusInvitaciones.respuesta);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();
        setEmpresaLogo(empresa.configuracion.img);

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }

        await obtInfoClientes();
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, [idSocios]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {
        habilitarCarrusel && (
          <ModalInicioSocios logo={empresaLogo} onClose={cierraCarrusel}/>
        )
      }

      <ObtUsuarioRemaxComponent idSocios={idSocios} />
      <div className="cont_Info">
        <h1 className="titt">Mis clientes</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div id="graficas_clientes" className="grid3">
          <div className="card_contenedor grid_col1">
            <h3>Total Clientes</h3>

            <div className="grafico_invitaciones">
              <div>
                <p className="margin0">No iniciados</p>
                <p className="margin0"><strong>{solicitudesCredito?.procesoNoIniciado ?? 0} clientes</strong></p>
              </div>
              <br/>
              <div>
                <p className="margin0">En proceso</p>
                <p className="margin0"><strong>{solicitudesCredito?.enProceso ?? 0} clientes</strong></p>
              </div>
              <br/>
              <div>
                <p className="margin0">Completados</p>
                <p className="margin0"><strong>{solicitudesCredito?.completado ?? 0} clientes</strong></p>
              </div>
            </div>
          </div>

          <div className="card_contenedor grid_col2">
            <h3>Clientes en proceso de firmar su crédito</h3>
            <div>
              <GraficaInvitaciones estadisticas={solicitudesCredito?.estadisticasInvitaciones} noInvitaciones={solicitudesCredito?.noInvitaciones}/>
            </div>
          </div>
        </div>
        

        <p className="descripcionClientes">
          Da click en el nombre para conocer más de cada uno de tus clientes:​
        </p>
        <div className="tabla_clientes">
          <TablaClientes arrData={clientes}/>
        </div>
        <br/>

      </div>
    </>
  );
}