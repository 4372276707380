import "../App.css";
import "../sass/style.css";
import isotipo_morado from "../images/isotipo_morado.svg";
import logo_morado from "../images/logo_morado.svg";
import { obtEmpresaUrl } from "../utils/global_functions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario no esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Footer() {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    async function obtEmpresaConfig(){
      const config = await obtEmpresaUrl();
      setLogo(config.confiAdicional?.logoBlanco == undefined ? "" : config.confiAdicional.logoBlanco);
    }

    obtEmpresaConfig();
  }, []);

  return (
    <>
      <footer>
        <div className="wrapper">
          <div>
            <div>
              <Link to="/" className="isotipo_blanco">
                <img className="isotipo_blanco" src={logo} alt="saturn 5" />
              </Link>
            </div>

            <div className="menu-politicas">
              
                <li>
                  {" "}
                  <Link to="/avisoDePrivacidad" className="link">
                    Aviso de privacidad
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/terminosCondiciones" className="link">
                    Términos y condiciones
                  </Link>
                </li>
                <li>
                  <p className="copy">© 2024. Saturn5 Technologies SAPI de CV</p>
                </li>
              
            </div>

            <div className="menu-footer">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/calculadora">Solicita tu crédito</Link>
                </li>
                {/* <li>
                  <a
                    href="https://saturn5.mx/#section-101-17"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contacto
                  </a>
                </li> */}
              </ul>
              <ul>
                <li>
                  <Link to="/">Login</Link>
                </li>
                <li>
                  <Link to="/crearcuentaSinSimulacion">Crear cuenta</Link>
                </li>
              </ul>  
            </div>

            <div className="redes-footerSaturn" id="redes-footerSaturn">
              <ul className="redes">
                <li>
                  <a
                    href="https://www.instagram.com/saturn5mx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/saturn5mx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/saturn5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="redes-footerFinmax" id="redes-footerFinmax">
              <ul className="redes">
                <li>
                  <a
                    href="https://www.instagram.com/remaxmex/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/RemaxMex"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@remaxmexico/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/remaxmexico"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>

            {/* Redes sociales de Consultores */}

            <div
              className="redes-footerConsultores"
              id="redes-footerConsultores"
            >
              <ul className="redes">
                <li>
                  <a
                    href="https://www.instagram.com/mymconsultores?igsh=OWFkc21laHV2Y3A1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/myminmuebles/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/mymconsultoresinmobiliarios/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="redes-footerArchandel" id="redes-footerArchandel">
              <ul className="redes">
                <li>
                  <a
                    href="https://www.instagram.com/archandelrealestate/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/archandelrealestate/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/archandel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="redes-footerOpera" id="redes-footerOpera">
              <ul className="redes">
                <li>
                  <a
                    href="https://www.instagram.com/opera.desarrolladora/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/operad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://mx.linkedin.com/company/opera-desarrolladora"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>

          </div>
          <div className="copyright">
            <p>© 2024. Saturn5 Technologies SAPI de CV</p>
            {/* <img src={logo_morado} alt="saturn 5" /> */}
          </div>
        </div>
      </footer>
    </>
  );
}
