import "../../App.css";
import * as ReactDOM from "react-dom/client";
import { useState, useEffect } from "react";
import { obtEmpresaUrl } from "../../utils/global_functions";
import { Link, useNavigate } from "react-router-dom";
import ImgMiHipoteca from "../../images/MiHipoteca.jpg"
import { get } from "../../utils/http";
import Cookies from "universal-cookie";

function CardHipoteca({index, datosHipoteca, onClickDetalles, onClickEditar}){
  const formatoFechaAMD = (fecha) => {
    const partes = fecha.split("T")[0].split('-');
    return `${partes[2]}-${partes[1]}-${partes[0]}`
  }

  const formatoMoneda = (numero) => `$ ${new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(numero)}`;

  return(
    <li key={index}  className="cont_loop">
      <h3 className="textoCentrado">{datosHipoteca.identificador}</h3>
      <p className="descripcion margin_top_20">Valor de propiedad</p>
      <p className="precio">
        {formatoMoneda(datosHipoteca.valorCompraTotal)}
      </p>
      <div className="plazo plazoCardResumen">
        <div className="datosCardResumen">
          <p className="widthCompleto">
            Plazo <span>{datosHipoteca.plazoCredito} años</span>
          </p>
        </div>
        <div>
          <p className="widthCompleto">
            Creado{" "}
            <span>{formatoFechaAMD(datosHipoteca.fechaCreacion)}</span>
          </p>
        </div>
      </div>

      <div className="cont_flex_btns">
        <a href="#/"
          className="ancho70 btn btn_morado textoCentrado"
          onClick={onClickDetalles}
        >
          Ver detalle
        </a>
        <a href="#/"
          className="link"
          onClick={onClickEditar}
        >
          Editar
        </a>
      </div>
    </li>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones APLICADAS del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function MiHipoteca() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await cargarHipotecas();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
  }, []);

  async function cargarHipotecas(){
    const respuesta = await get("getHipotecas", parseInt(cookies.get("id")));
    //console.log("Respuesta: ", respuesta);
    
    let hipotecas = respuesta.statuscode === 200 ? respuesta.respuesta : [];

    const tarjetasHipotecas = hipotecas.map((hipoteca, index) => 
      <CardHipoteca index={index} datosHipoteca={hipoteca} 
        onClickDetalles={ () => {
          navigate("/miHipoteca/amortizacion")
          localStorage.setItem("idHipoteca", hipoteca.idHipoteca);
        }}
        onClickEditar={() => {
          navigate("/miHipoteca/misDatosDeHipoteca");
          localStorage.setItem("idHipoteca", hipoteca.idHipoteca);
        }}
        />)

    const root = ReactDOM.createRoot(document.getElementById("listaHipotecas"));
    root.render(tarjetasHipotecas);
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1 className="titt">Mi Hipoteca</h1>
        <p>
          Agrega la información de tu hipoteca para visualizar tus pagos, 
          hacer proyecciones y simular cómo las aportaciones adicionales podrían ayudarte a pagarla más rápido. 
          Nuestra herramienta te permite optimizar tu hipoteca para que puedas reducir intereses 
          y tener más libertad financiera.
        </p>

        <div className="card_btn">
          <Link to="/miHipoteca/misDatosDeHipoteca" 
            onClick={() => {localStorage.removeItem("idHipoteca")}}
          >
            <img src={ImgMiHipoteca} alt="/misDatosDeHipoteca" />
            <p className="overlay">Agrega tu hipoteca</p>
          </Link>
        </div>
          

        <div id="listaHipotecas" className="cont_flex con_flex_wrap contenido_centrado flex_start">
        </div>

      </div>
      
    </>
  );
}
