import React, { useState, useEffect } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { TablaInvitacionesEjecutivo } from "../../../components/Tablas.jsx";
import { get } from "../../../utils/http.js";

export default function InvitacionesEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [invitaciones, setInvitaciones] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        const peticion = await get("obtUltimasInvitaciones", "");

        if(peticion?.statuscode === 200){
          //console.log(peticion.respuesta);
          setInvitaciones(peticion.respuesta);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Invitaciones</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div className="tabla_clientes">
          <TablaInvitacionesEjecutivo invitaciones={invitaciones}/>
        </div>
        <br/>

      </div>
    </>
  );
}