import React, { useState, useEffect, useMemo, useRef } from "react";
import * as ReactDOM from "react-dom/client";
import { useForm } from "react-hook-form";
import { actualizarEstatusInvitacion, obtEmpresaUrl } from "../../../utils/global_functions.js";
import { textoAMoneda, agergarDecimales, formatoMoneda, formatoFechaIsoADma, formatoFechaDmaAIso, calcularEdad, round } from "../../../utils/operaciones_formulario.js";
import { ruleCP, ruleEmail, ruleNacimiento, ruleRFC } from "../../../assets/validateRules.js";
import { get, getCP, post, put } from "../../../utils/http.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ModalLoading } from "../../../components/Modals.jsx";

export default function RegistrarClienteEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [direccionNueva, setDireccionNueva] = useState(false);
  const [liquidez, setLiquidez] = useState(false);
  const [plazoPermitidoTipoProyecto, setPlazoPermitidoTipoProyecto] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const tipoRegistro = useRef(0);

  // Dropdowns
  const [catCreditosAdicionales, setCatCreditosAdicionales] = useState([]);
  const [catSumIngresos, setCatSumIngresos] = useState([]);
  const [catDemoCoIngresos, setCatDemoCoIngresos] = useState([]);
  const [catColonias, setCatColonias] = useState([]);

  // Constantes
  const fpmValues = { P5: 22.04, P10: 14.26, P15: 11.93, P20: 10.9 };
  const relPagIng = 0.35;

  const navigate = useNavigate();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enganche: "$ 0.00",
      valorPropiedad: "$ 0.00",
      ingresos: "$ 0.00",
      montoCredito: "$ 0.00",
      ingresoCoacreditado: "$ 0.00",
      creditoAdicional: null
    }
  });

  const creditoAdicional = watch("creditoAdicional");
  const coacreditado = watch("coacreditado");
  const plazo = watch("plazo");
  const fechaNacimiento = watch("fechaNacimiento");
  const tipoProyecto = watch("tipoProyecto");


  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await renderDropdowns();
        await inicializarFormulario();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  async function inicializarFormulario(){
    const idCliente = localStorage.getItem("idCliente");
    localStorage.removeItem("idCliente");
    if (idCliente !== null){
      await cargarDatosUsuario(parseInt(idCliente));
      tipoRegistro.current = 1;
    }else{
      const idInvitacion = localStorage.getItem("idInvitacion");
      localStorage.removeItem("idInvitacion");
      if(idInvitacion !== null){
        await cargarDatosInvitacion(parseInt(idInvitacion));
        tipoRegistro.current = 2;
      }
    }
  }

  async function cargarDatosUsuario(idUsuario){
    //console.log("Cargando datos de ", idUsuario);
    const peticion = await get("obtDatosCliente", idUsuario);

    if(peticion?.statuscode === 200){
      const datosUsuario = peticion.respuesta;
      //console.log("Datos: ", datosUsuario);
      setValue("idUsuario", idUsuario);
      setValue("nombre", datosUsuario.nombre);
      setValue("apellidoPaterno", datosUsuario.paterno);
      setValue("apellidoMaterno", datosUsuario.materno);
      setValue("rfc", datosUsuario.rfc);
      setValue("telefono", datosUsuario.telefono);
      setValue("email", datosUsuario.correo);
      setValue("fechaNacimiento", formatoFechaIsoADma(datosUsuario.fNacimiento));
      setValue("ingresos", formatoMoneda(datosUsuario.ingresoB));
      setValue("cp", datosUsuario.cp);
      setValue("direccion", datosUsuario.direccion);
      setValue("colonia", datosUsuario.colonia);
      setValue("numeroExterior", datosUsuario.nExteriors);
      setValue("numeroInterior", datosUsuario.nInterior);
      setValue("estado", datosUsuario.estado);
      setValue("delegacion", datosUsuario.ciudad);
    }
  }

  async function cargarDatosInvitacion(idInvitacion){
    const peticion = await get("obtDatosClienteInvitacion", idInvitacion);
    
    if( peticion?.statuscode === 200){
      const datosUsuario = peticion.respuesta;
      setValue("idInvitacion", idInvitacion);
      setValue("idSocio", datosUsuario.idSocios);
      setValue("nombre", datosUsuario.nombre);
      setValue("apellidoPaterno", datosUsuario.paterno);
      setValue("apellidoMaterno", datosUsuario.materno);
      setValue("telefono", datosUsuario.telefono);
      setValue("email", datosUsuario.correo);
    }
  }

  const renderDropdowns = async () => {
    let catalogos = await get("getCatalogosPrecalifica", "");
    if (catalogos.statuscode === 200) {
      let root;
      let opcionDefault = (
        <option value="" selected disabled>
          Seleccione una opción...
        </option>
      );

      const actividades = catalogos.catalogosPrecalifica.actividadesEconomicas.map(
        (proyecto) => (
          <option
            value={proyecto.idActividadEconomica}
            key={proyecto.idActividadEconomica}
          >
            {proyecto.nombreActividad}
          </option>
        )
      );
      actividades.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectActividadEconomica")
      );
      root.render(actividades);

      const bancos = catalogos.catalogosPrecalifica.bancos.map((proyecto) => (
        <option value={proyecto.idBanco} key={proyecto.idBanco}>
          {proyecto.nombreBanco}
        </option>
      ));
      bancos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectBanco"));
      root.render(bancos);

      let demuestraIngresos = catalogos.catalogosPrecalifica.demoCoIngresos.map(
        (proyecto) => (
          <option
            value={proyecto.idDemoCoIngreso}
            key={proyecto.idDemoCoIngreso}
          >
            {proyecto.nombreDemoCi}
          </option>
        )
      );
      demuestraIngresos.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectDemuestroIngresos")
      );
      root.render(demuestraIngresos);

      const estados = catalogos.catalogosPrecalifica.estados.map((proyecto) => (
        <option value={proyecto.idEstado} key={proyecto.idEstado}>
          {proyecto.nombre}
        </option>
      ));
      estados.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectEstadoCompra"));
      root.render(estados);

      const proyectos = catalogos.catalogosPrecalifica.tiposPRoyectos.map(
        (proyecto) => (
          <option value={proyecto.idTipoProyecto} key={proyecto.idTipoProyecto}>
            {proyecto.nombreTproyecto}
          </option>
        )
      );
      proyectos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectTipoProyecto"));
      root.render(proyectos);

      const etapas = catalogos.catalogosPrecalifica.etapasProyectos.map(
        (proyecto) => (
          <option
            value={proyecto.idEtapaProyecto}
            key={proyecto.idEtapaProyecto}
          >
            {proyecto.nombreEtapa}
          </option>
        )
      );
      etapas.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectEtapaProyecto")
      );
      root.render(etapas);

      setCatCreditosAdicionales([...catalogos.catalogosPrecalifica.creditosAdicionales]);
      setCatSumIngresos([...catalogos.catalogosPrecalifica.catSumIngresos]);
      setCatDemoCoIngresos([...catalogos.catalogosPrecalifica.demoCoIngresos]);
    }
  }

  const calcularCredito = (tiempo, valorPropiedad, enganche) => {
    if (tiempo === 0) return false;
    if (valorPropiedad < 500000) return false;

    let fpmVal = fpmValues["P" + tiempo];
    let finaciamientoVal = round(valorPropiedad - enganche, 2);

    let mensualidad = round((finaciamientoVal * fpmVal) / 1000, 2);
    let sueldo = mensualidad / relPagIng;

    //setImb(sueldo);

    return {
      valorPropiedad: valorPropiedad,
      enganche: enganche,
      tasa: fpmVal,
      plazo: tiempo,
      montoPrestar: finaciamientoVal,
      mensualidad: mensualidad,
    }
  };

  const enviar = async (datos) => {
    //console.log("Datos enviados: ", datos);

    if(plazoPermitidoTipoProyecto){

      if(tipoRegistro.current === 1){ // Usuario creado: nueva cotización
        const userData = {
          id: datos.idUsuario,
          correo: datos.email,
          nombre: datos.nombre,
          paterno: datos.apellidoPaterno,
          materno: datos.apellidoMaterno,
          fNacimiento: formatoFechaDmaAIso(datos.fechaNacimiento),
          ingresoB: Number(datos.ingresos.replace(/[^0-9.]/g, "")),
          telefono: datos.telefono,
          rfc: datos.rfc,
        };
  
        await put("updateUser", userData);
      }
      
      const creditoCalculado = calcularCredito(Number(datos.plazo), Number(datos.valorPropiedad.replace(/[^0-9.]/g, "")), Number(datos.enganche.replace(/[^0-9.]/g, "")));
      const edadCliente = calcularEdad(formatoFechaDmaAIso(datos.fechaNacimiento));
  
      let data = {
        user: {
          correo: datos.email,
          nombre: datos.nombre,
          paterno: datos.apellidoPaterno,
          materno: datos.apellidoMaterno,
          fNacimiento: formatoFechaDmaAIso(datos.fechaNacimiento),
          ingresoB: Number(datos.ingresos.replace(/[^0-9.]/g, "")),
          telefono: datos.telefono,
          rfc: datos.rfc,
        },
        domicilio: {
          direccion: datos.direccion,
          nExteriors: datos.numeroExterior,
          nInterior: datos.numeroInterior,
          colonia: datos.colonia,
          ciudad: datos.delegacion,
          estado: datos.estado,
          cp: datos.cp,
        },
        cuestionario: {
          edad: edadCliente,
          brutoMensual: Number(datos.ingresos.replace(/[^0-9.]/g, "")),
          proyecto:
            "Solictud Saturn 5: " +
            datos.nombre +
            " " +
            datos.apellidoPaterno +
            " " +
            datos.apellidoMaterno,
          etapa: "Pre-Califica paso 1",
          valorCredito: datos.valorPropiedad.replace(/[^0-9.]/g, ""),
        },
        calculadora: {
          fechaCrecion: new Date().toISOString(),
          valorPropiedad: creditoCalculado.valorPropiedad,
          plazo: creditoCalculado.plazo,
          montoSolicitado: creditoCalculado.montoPrestar,
          enganche: creditoCalculado.enganche,
          tasaPromedio: creditoCalculado.tasa,
          mensualidad: creditoCalculado.mensualidad,
        }
      }

      if(tipoRegistro.current === 2){ // Creación de usuario por invitación
        data.user.idSocios = datos?.idSocio;
        data.user.idInvitacion = datos?.idInvitacion;
      }

      //console.log("Data: ", data);
  
      let prepaso1 = await post("prePaso1Ejecutivo", data);
      //console.log("prepaso1: ", prepaso1);
  
      if (prepaso1.statuscode === 200) {
        if(tipoRegistro.current === 2){ // Creación de usuario por invitación
          actualizarEstatusInvitacion(datos.idInvitacion, 3);
        }

        setIsModalLoading(true);
        let obtIdResP1porAPI = await get("userCotizaciones", prepaso1?.userId);
        //console.log("user cotizaciones: ", obtIdResP1porAPI);
  
        if (obtIdResP1porAPI.statucode === 200) {
          const idResP1 = parseInt(obtIdResP1porAPI.cotizaciones.at(-1).id);
          
          const dataCuestionario = {
            idUser: prepaso1?.userId,
            solicitud: {
              nameAgent: "Saturn 5",
              emailAgent: "contacto@saturn5.mx",
              realStateDevelopment: "Desarrollo inmobiliario",
              mobileAgent: "0000000000",
              afiliacion: datos.creditoAdicional === 1 ? parseInt(datos.credito) : 0,
              beneficio_afiliacion: datos.creditoAdicional === 1 ? Number(datos.montoCredito.replace(/[^0-9.]/g, "")) : 0,
              con_coacreditado: datos.coacreditado,
              quien: datos.coacreditado === 1 ? parseInt(datos.sumarIngresos) : 0,
              demuestro_ingresos_coa: datos.coacreditado === 1 ? Number(datos.ingresosCoacreditado.replace(/[^0-9.]/g, "")) : 0,
              ahorro_credito: creditoCalculado.enganche,
              valor_credito: creditoCalculado.valorPropiedad,
              tile: "string",
              conBuro: false,
            },
            cuestionario: {
              idUser: prepaso1?.userId,
              idTipoProyecto: parseInt(datos.tipoProyecto),
              idEtapaProyecto: parseInt(datos.etapaProyecto),
              idActividadEconomica: parseInt(datos.actividadEconomica),
              idBanco: parseInt(datos.banco),
              idEstado: parseInt(datos.estadoCompra),
              idCredito:parseInt(datos.creditoAdicional),
              idDemoCI: parseInt(datos.demuestroIngresos),
              sumaIngresos: Boolean(datos.coacreditado),
              coIngreso: datos.coacreditado === 1 ? Number(datos.ingresoCoacreditado.replace(/[^0-9.]/g, "")) : 0,
              referencia: "Saturn 5",
              idResPaso1: idResP1,
            }
          }
          //console.log("dataCuestionario: ", dataCuestionario);

          let dataPost = await post("agregarCuestionario", dataCuestionario);
          //console.log("agregarCuestionario: ", dataPost);
  
          if (dataPost?.statuscode === 200) {
            let pasarIdCuestionario = await get("obtIdCuestionarioLlenarPreAutoriza", dataPost.cuestionarioId);
            //console.log("obtIdCuestionarioLlenarPreAutoriza: ", pasarIdCuestionario);
            
            if (pasarIdCuestionario.statuscode === 200) {
              
              //localStorage.removeItem("idResPaso1Cuestionario")
              setIsModalLoading(false);
              Swal.fire({
                icon: "success",
                title: "¡Listo! Ya tenemos resultados para tu cliente.",
                text: "Datos guardados correctamente",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
              }).then(resultado => {
                navigate(tipoRegistro.current === 2 ? "/ejecutivo/invitaciones" : "/ejecutivo/clientes");
              });
            }else{
              Swal.fire({
                title: "Algo salió mal",
                text: "Inténtelo nuevamente más tarde",
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            }
          } else if (dataPost.statuscode === 400) {
            setIsModalLoading(false);
            Swal.fire({
              title: "Algo salió mal",
              text: "Inténtelo nuevamente más tarde",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          } else if (dataPost.statuscode === 201) {
            setIsModalLoading(false);
  
            let responseMsg = await get("obtResPrecalificaSinBancosPorIdCuestionario", dataPost.idCuestionario);
            //console.log("obtResPrecalificaSinBancosPorIdCuestionario: ", responseMsg);
            
            if (responseMsg.statucode === 200) {
              Swal.fire({
                title: "No tuvo respuesta de bancos",
                text: "¿Crear nueva cotización?",
                icon: "error",
                confirmButtonText: "Si",
                showDenyButton: true,
                denyButtonColor: "#E52D2D",
                denyButtonText: "No"
              }).then((resultado) => {
                if(resultado.isDenied){
                  navigate("/ejecutivo/clientes");
                }
              });
            }
          }
        }else{
          setIsModalLoading(false);
          Swal.fire({
            title: "Algo salió mal",
            text: "Inténtelo nuevamente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }else{
        Swal.fire({
          title: "Algo salió mal",
          text: "Verifica que los datos del usuario sean correctos",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    }else{
      Swal.fire({
        title: "Algo salió mal",
        text: "Verifica que los datos sean correctos",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const obtenerDataCp = async (cp) => {
    let respuestaPorCP = await getCP("info_cp", cp);

    obtenerClaveIso(respuestaPorCP.response.estado);
    //listColoniasCP(respuestaPorCP.response.asentamiento);
    setDireccionNueva(true);
    setCatColonias(respuestaPorCP.response.asentamiento);
    setValue("delegacion", respuestaPorCP.response.municipio);
  };

  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    if (obtenerClave.statuscode === 200) {
      setValue("estado", obtenerClave.iso);
      //setEstado(obtenerClave.iso);
    }else{
      setValue("estado", estadoCP);
      //setEstadoAux(estadoCP);
    }
  }

  useMemo(() => {
    if(fechaNacimiento){
      if(ruleNacimiento(fechaNacimiento)){
        const totalPlazoEdad = parseInt(calcularEdad(formatoFechaDmaAIso(fechaNacimiento))) + parseInt(plazo);
        setLiquidez(totalPlazoEdad <= 80);
      }else{
        setLiquidez(false);
      }
    }else{
      setLiquidez(false);
    }

    setPlazoPermitidoTipoProyecto(!(parseInt(plazo) === 20 && tipoProyecto === "4"));
  }, [fechaNacimiento, tipoProyecto, plazo])

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className="cont_Info">
        <h1 className="titt">Ingresar cliente</h1>
        <p className="descripcionClientes">
          Aquí puedes ingresar un cliente nuevo
        </p>

        <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(enviar)}>
              
              <div className="col3 campo-form">
                <label>
                  Enganche
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  {...register("enganche", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}               
                />
                {errors.enganche?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Valor de la propiedad</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...register("valorPropiedad", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}               
                />
                {errors.valorPropiedad?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form estado">
                <label>Plazo</label>
                <select
                  {...register("plazo", { required: true })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Seleccione una opción...
                  </option>
                  <option value={5}>
                    5 años
                  </option>
                  <option value={10}>
                    10 años
                  </option>
                  <option value={15}>
                    15 años
                  </option>
                  <option value={20}>
                    20 años
                  </option>
                </select>
                {errors.plazo?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              
              <br/><br/>
              <hr/>

              <div className="col3 campo-form">
                <label>Nombre</label>
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("nombre", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.nombre?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>RFC</label>
                <input
                  type="text"
                  minLength={13}
                  maxLength={13}
                  autoComplete="off"
                  {...register("rfc", { required: true, validate: (rfc) => ruleRFC(rfc) })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                  }}
                />
                {errors.rfc?.type === "required" ? (
                  <span className="span">Este campo es requerido</span>
                ) : (
                  errors.rfc?.type === "validate" && (
                    <span className="span">Formato no válido</span>
                  )
                )}
              </div>
              <div className="col3 campo-form">
                <label>Fecha de Nacimiento</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="dd/mm/aaaa"
                  {...register("fechaNacimiento", {required: true, validate: fecha => ruleNacimiento(fecha)})}
                  onInput={(e) =>{
                    let fecha = e.target.value.replace(/\D/g, "");
                    // Insertar las diagonales en la posición correcta
                    if (fecha.length > 2 && fecha.length < 5) {
                      fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
                    } else if (fecha.length > 4) {
                      fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
                    }
                    e.target.value = fecha;
                  }}
                />
                {errors.fechaNacimiento?.type === "required" ? (
                  <span className="span">Este campo es requerido</span>
                ) : (
                  errors.fechaNacimiento?.type === "validate" && (
                    <span className="span">Formato no válido</span>
                  )
                )}
              </div>

              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input
                  type="text"
                  maxLength={15}
                  autoComplete="off"
                  {...register("apellidoPaterno", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.apellidoPaterno?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Telefono</label>
                <input
                  type="tel"
                  minLength={10}
                  maxLength={10}
                  autoComplete="off"
                  {...register("telefono", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                  }}
                />
                {errors.telefono?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
                <small>
                  Asegura que el número de teléfono sea válido
                </small>
              </div>
              <div className="col3 campo-form">
                <label>Ingresos</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...register("ingresos", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}               
                />
                {errors.ingresos?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Apellido Materno</label>
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("apellidoMaterno", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.apellidoMaterno?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>E-mail</label>
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("email", { required: true, validate: (email) => ruleEmail(email) })}
                  
                />
                {errors.email?.type === "required" ? (
                  <span className="span">Este campo es requerido</span>
                ) : (
                  errors.email?.type === "validate" && (
                    <span className="span">Formato no válido</span>
                  )
                )}
              </div>

              <br/><br/>

              <div className="col3 campo-form">
                <label>Codigo Postal</label>
                <input
                  type="tel"
                  minLength={5}
                  maxLength={5}
                  autoComplete="off"
                  {...register("cp", { required: true, validate: (cp) => ruleCP(cp) })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                    if (e.target.value.length === 5) {
                      obtenerDataCp(e.target.value);
                    }
                  }}
                />
                {errors.cp?.type === "required" ? (
                  <span className="span">Este campo es requerido</span>
                ) : (
                  errors.cp?.type === "validate" && (
                    <span className="span">Formato no válido</span>
                  )
                )}
              </div>
              <div className="col3 campo-form">
                <label>Número exterior</label>
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("numeroExterior", { required: true })}
                />
                {errors.numeroExterior?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Estado</label>
                <input
                  disabled
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("estado", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                  }}
                />
                {errors.estado?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Dirección</label>
                <input
                  type="text"
                  maxLength={200}
                  autoComplete="off"
                  {...register("direccion", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
                  }}
                />
                {errors.direccion?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Número interior</label>
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("numeroInterior")}
                />
              </div>
              <div className="col3 campo-form">
                <label>Delegación / Municipio</label>
                <input
                  disabled
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  {...register("delegacion", { required: true })}
                />
                {errors.delegacion?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Colonia</label>
                {
                  direccionNueva ? (
                    <select
                      id="selectColonia"
                      {...register("colonia", { required: true })}
                    >
                      <option value="" selected disabled>
                        Seleccione una opción...
                      </option>
                      {
                        catColonias.map((colonia) => (
                          <option value={colonia} key={colonia}>
                            {colonia}
                          </option>
                        ))
                      }
                    </select>
                  ) : (
                    <input
                      type="text"
                      disabled
                      value={getValues("colonia")}
                    />
                  )
                }
                {errors.colonia?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <br/><br/>
              <hr/>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Qué tipo de proyecto es?</label>
                <select
                  id="selectTipoProyecto"
                  {...register("tipoProyecto", { required: true })}
                  disabled={!plazo}
                >
                </select>
                {errors.tipoProyecto?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
                {liquidez === false && tipoProyecto === "4" && (
                  <small className="span">
                    Al seleccionar esta opción, probablemente su solicitud sea
                    rechazada, la suma del plazo más su edad debe de ser menor o
                    igual a 80 años.{" "}
                  </small>
                )}
                {plazoPermitidoTipoProyecto === false && (
                  <small className="span">
                    {" "}
                    Esta opción solo se puede seleccionar con los plazos 5, 10 y
                    15 años.{" "}
                  </small>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿Cuál es tu actividad económica?</label>
                <select
                  id="selectActividadEconomica"
                  {...register("actividadEconomica", { required: true })}
                >
                </select>
                {errors.actividadEconomica?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿En qué estado vas a comprar?</label>
                <select
                  id="selectEstadoCompra"
                  {...register("estadoCompra", { required: true })}
                >
                </select>
                {errors.estadoCompra?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿En qué etapa de tu proyecto te encuentras?</label>
                <select
                  id="selectEtapaProyecto"
                  {...register("etapaProyecto", { required: true })}
                >
                </select>
                {errors.etapaProyecto?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿Cómo demuestras tus ingresos?</label>
                <select
                  id="selectDemuestroIngresos"
                  {...register("demuestroIngresos", { required: true })}
                >
                </select>
                {errors.demuestroIngresos?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿De qué banco eres cliente?</label>
                <select
                  id="selectBanco"
                  {...register("banco", { required: true })}
                >
                </select>
                {errors.banco?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <input
                  type="hidden"
                  {...register('creditoAdicional', { required: true})}
                />
                <label>
                  ¿Desea ocupar el credito de vivienda del Infonavit o
                  Fovissste?{" "}
                  {errors.creditoAdicional?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </label>
                <div className="botones">
                  <button
                    type="button"
                    className={creditoAdicional === 1 ? "btn btn_morado" : "button_YN button"}
                    onClick={() => setValue('creditoAdicional', 1, { shouldValidate: true })}
                  >
                    {" "}
                    Si
                  </button>
                  <button
                    type="button"
                    className={creditoAdicional === 0 ? "btn btn_morado" : "button_YN button"}
                    onClick={() => setValue('creditoAdicional', 0, { shouldValidate: true })}
                  >
                    {" "}
                    No
                  </button>
                </div>
              </div>

              {
                creditoAdicional === 1 && (
                  <>
                    <div className="col3 campo-form estado marginEspacio">
                      <label>¿Cúal credito desea ocupar?</label>
                      <select
                        {...register("credito", { required: true })}
                      >
                        <option value="" selected disabled>
                          Seleccione una opción...
                        </option>
                        {
                          catCreditosAdicionales.map((creditoAdicionalOp) => (
                            <option value={creditoAdicionalOp.idCredito} key={creditoAdicionalOp.idCredito}>
                              {creditoAdicionalOp.nombreCredito}
                            </option>
                          ))
                        }
                      </select>
                      {errors.credito?.type === "required" && (
                        <span className="span">Este campo es requerido</span>
                      )}
                    </div>
                    <div className="col3 campo-form estado marginEspacio">
                      <label>Monto disponible de su credito</label>
                      <input
                        type="text"
                        autoComplete="off"
                        {...register("montoCredito", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                        onInput={(e) => {
                          e.target.value = textoAMoneda(e.target.value);
                        }}
                        onBlur={(e) => {
                          e.target.value = agergarDecimales(e.target.value);
                        }}               
                      />
                      {errors.montoCredito?.type === "validate" && (
                        <span className="span">Este campo es requerido</span>
                      )}
                    </div>
                  </>
                )
              }

              <div className="col3 campo-form estado marginEspacio">
                <input
                  type="hidden"
                  {...register('coacreditado', { required: true })}
                />
                <label>
                  ¿Deseas sumar ingresos con alguien para contar un crédito mayor?{" "}
                  {errors.coacreditado?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </label>
                <div className="botones">
                  <button
                    type="button"
                    className={coacreditado === 1 ? "btn btn_morado" : "button_YN button"}
                    onClick={() => setValue('coacreditado', 1, { shouldValidate: true })}
                  >
                    {" "}
                    Si
                  </button>
                  <button
                    type="button"
                    className={coacreditado === 0 ? "btn btn_morado" : "button_YN button"}
                    onClick={() => setValue('coacreditado', 0, { shouldValidate: true })}
                  >
                    {" "}
                    No
                  </button>
                </div>
              </div>

              {
                coacreditado === 1 && (
                  <>
                    <div className="col3 campo-form estado marginEspacio">
                      <label>¿Con quién deseas sumar ingresos?</label>
                      <select
                        {...register("sumarIngresos", { required: true })}
                      >
                        <option value="" selected disabled>
                          Seleccione una opción...
                        </option>
                        {
                          catSumIngresos.map((sumIngresos) => (
                            <option value={sumIngresos.idSumIngresos} key={sumIngresos.idSumIngresos}>
                              {sumIngresos.nombreSumIngresos}
                            </option>
                          ))
                        }
                      </select>
                      {errors.sumarIngresos?.type === "required" && (
                        <span className="span">Este campo es requerido</span>
                      )}
                    </div>
                    <div className="col3 campo-form estado marginEspacio">
                      <label>¿Cómo demuestra ingresos tu coacreditado?</label>
                      <select
                        {...register("ingresosCoacreditado", { required: true })}
                      >
                        <option value="" selected disabled>
                          Seleccione una opción...
                        </option>
                        {
                          catDemoCoIngresos.map((demoCoIngresos) => (
                            <option value={demoCoIngresos.idDemoCoIngreso} key={demoCoIngresos.idDemoCoIngreso}>
                              {demoCoIngresos.nombreDemoCi}
                            </option>
                          ))
                        }
                      </select>
                      {errors.ingresosCoacreditado?.type === "required" && (
                        <span className="span">Este campo es requerido</span>
                      )}
                    </div>
                    <div className="col3 campo-form estado marginEspacio">
                      <label>¿Cuál es el ingreso demostrable de tu coacreditado?</label>
                      <input
                        type="text"
                        autoComplete="off"
                        {...register("ingresoCoacreditado", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                        onInput={(e) => {
                          e.target.value = textoAMoneda(e.target.value);
                        }}
                        onBlur={(e) => {
                          e.target.value = agergarDecimales(e.target.value);
                        }}               
                      />
                      {errors.ingresoCoacreditado?.type === "validate" && (
                        <span className="span">Este campo es requerido</span>
                      )}
                    </div>
                  </>
                )
              }

              <br/><br/>
              
              <button 
                type="submit" 
                className="btn btn_morado"
              >
                Guardar
              </button>

              <br/><br/>

            </form>
          </div>

        <ModalLoading
          open={isModalLoading}
        ></ModalLoading>

        <br/>

      </div>
    </>
  );
}