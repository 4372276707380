import "../../../App.css";
import "../../../sass/style.css";
import FooterHome from "../../../components/FooterHome";
import NavEjecutivo from "../../../components/NavEjecutivo";
import HeaderEjecutivo from "../../../components/HeaderEjecutivo";


export default function TemplateEjecutivo({children,active}){
  return(
    <>
      <HeaderEjecutivo/>
        <div className="cont_flex">
          <NavEjecutivo active={active}/>
          {children}
        </div> 
      <FooterHome/>
    </>
  )
}