import isotipo_negro from "../images/isotipo-negro.svg";
import logo_negro from "../images/logotipo-negro.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { MenuLateralResponsivoEjecutivo } from "./NavEjecutivo";

export default function HeaderEjecutivo() {
  const [isOpenModalMenuLateral, setIsOpenModalMenuLateral] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const logOut = () => {
    cookies.remove("sessionEjecutivo", {path: "/"});
    cookies.remove("nombreEjecutivo", {path: "/"});
    cookies.remove("correoEjecutivo", {path: "/"});
    cookies.remove("idEjecutivo", {path: "/"});
    navigate("/");
  };

  return (
    <>
      <header className="header">
        <div>
          <Link to="/ejecutivo/clientes" className="isotipo">
            <img src={isotipo_negro} alt="logo" />
          </Link>
          <Link to="/ejecutivo/clientes" className={"ocultar_logoSaturn"}>
            {" "}
            <img src={logo_negro} alt="logo" />
          </Link>
        </div>
        <ul className="menu">
          <li className="logoSesionIniciada">
            <i className="fa  fa-user iconoUsuarioLoggueado"></i>
            <select
              id="select_login"
              onChange={() => {
                logOut();
              }}
            >
              <option>{cookies.get("nombreEjecutivo")}</option>
              <option>Cerrar Sesion</option>
            </select>
          </li>
        </ul>
      </header>

      <div className="header_responsive">
        <div></div>
        <button
          type="button"
          aria-label="Toggle Navigation"
          className="lines-button x"
          onClick={() => setIsOpenModalMenuLateral(true)}
        >
          <span className="lines"></span>
        </button>
        <MenuLateralResponsivoEjecutivo
          open={isOpenModalMenuLateral}
          close={() => setIsOpenModalMenuLateral(false)}
        ></MenuLateralResponsivoEjecutivo>
      </div>
    </>
  );
}
