import "../../App.css";
import img_olvidePass from "../../images/olvidePassword.jpg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { useForm } from "react-hook-form";
import { ruleEmail } from "../../assets/validateRules";
import { get } from "../../utils/http";
import Swal from "sweetalert2";
import { ModalLoadingGeneral } from "../../components/Modals";
import { obtEmpresaUrl } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------ Pantalla: Recuperar contraseña  ----------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function RecuperaPass() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(false);
  const navigate = useNavigate();
  const actionSetDataPublic = useSetDataPublic();

  const recover = () => {
    validarPass();
  };

  const enviarMailForm = async () => {
    setIsOpenModalLoading(true);
    let response2 = await get("enviarEmailCambioPassword", email);
    if (response2.statuscode === 200) {
      setIsOpenModalLoading(false);
      Swal.fire({
        title: "Se ha enviado un correo para asegurarnos que eres tú.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      setIsOpenModalLoading(false);
      Swal.fire({
        title: "Algo ha salido mal, intentelo más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const validarPass = async () => {
    let correo = email;
    let response = await get("verificarUsuarioRegistradoLocal", correo);
    actionSetDataPublic({ correo: email });
    if (response.statuscode === 200) {
      if(response?.autenticadoConGoogle){
        Swal.fire({
          title: "Autenticado con google",
          text: "Inicia sesión a traves de este método en la pantalla de inicio",
          icon: "info",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          navigate("/");
        });
      }else{
        enviarMailForm();
      }
    } else if (response.statuscode === 404) {
      Swal.fire({
        title: "El usuario no está registrado",
        icon: "warning",
        confirmButtonText: "Crear cuenta",
      }).then((result) => {
        navigate("/crearcuentaSinSimulacion");
      });
    } else if (response.statuscode === 400) {
      Swal.fire({
        title: "Parece que te has autenticado con google",
        text: "Intenta iniciar sesión usando este método",
        icon: "info",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        navigate("/");
      });
    }else {
      Swal.fire({
        title: "Algo salió mal",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className="cont_flex">
        <div className="col6">
          <h1>Olvidé mi contraseña</h1>
          <p>
            No te preocupes. Ingresa tu correo electrónico y te enviaremos un
            link de acceso para que crear una nueva contraseña
          </p>
          <form onSubmit={handleSubmit(recover)}>
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onInput={(ev) => {
                setEmail(ev.target.value);
              }}
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" && (
              <span>Este campo es requerdio</span>
            )}
            {errors.email && (
              <span>Este campo no tiene el formato correcto</span>
            )}
            <br />
            <br />
            <button type="submit" className="btn btn_morado w100">
              Enviar link
            </button>
          </form>
        </div>

        <div className="col6 cont_img-index">
          <img src={img_olvidePass} alt="recuperar contraseña" />
        </div>
        <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
      </div>
    </>
  );
}
