import { get, post, put, postDocs } from "./http";

//JPB 09-11-2023Para verificar si tiene coacreditado
/*export const verificarCoacreditado = async (idCuestionarioSolicitud)  => {
    console.log(idCuestionarioSolicitud);
    let actualizarPantallaAct = await get("getCredito", idCuestionarioSolicitud );
    console.log(actualizarPantallaAct);
    if(actualizarPantallaAct.statuscode === 200){
        //console.log("Si tiene IDCREDITO");          
        return true;
    }
    else{
        //console.log("No tiene IDCREDITO"); 
        return false;
    }
}*/
export const verificarCoacreditado = async (idUsuario) => {
  let actualizarPantallaAct = await get(
    "verificarSiExisteCoAcreditado",
    idUsuario
  );
  if (actualizarPantallaAct.statuscode === 200) {
    //console.log("Si tiene IDCREDITO");
    return true;
  } else {
    //console.log("No tiene IDCREDITO");
    return false;
  }
};

//Para guardar los pasos de las
export const guardarPaso = async (
  idPantalla,
  idCuestionarioSolicitud,
  idResPrecalifica
) => {
  let dataActualizarPantalla = {
    idResPrecalifica: idResPrecalifica,
    idCuestionario: idCuestionarioSolicitud,
    idCatPantallas: idPantalla,
  };

  let actualizarPantallaAct = await put(
    "updatePantallaActual",
    dataActualizarPantalla
  );
  if (actualizarPantallaAct.statuscode === 200)
    console.log("Pantalla actualizada");
};

//Para actualizar los estatus de los socios invitacion
export const actualizarEstatusInvitacion = async (idInvitacion, estatusId) => {
  // console.log(idInvitacion, estatusId);
  //Siempre vamos a evaluar hacia arriba
  //Entonces solo podemos actualizar el estatus si es mayor al que esta actualmente en bd
  let obtInvitacionInfo = await get("getInvitacion", idInvitacion);
  // console.log(obtInvitacionInfo);
  if (obtInvitacionInfo.statuscode === 200) {
    let ultimoEstatus = obtInvitacionInfo.infoInvitacion[0].estatus;
    // console.log(ultimoEstatus);
    if (estatusId > ultimoEstatus) {
      let params = {
        idInvitacion: idInvitacion,
        estatus: estatusId,
      };
      let actualizarEstatus = await put("actualizaEstatusInvitacion", params);
      if (actualizarEstatus.statuscode === 200)
        console.log("");
    }
  } else {
    // console.log("No existe la invitacion");
  }
};

export const verificarCoAcreditado = async (
  idCuestionarioSolicitud,
  idResPrecalifica
) => { };

export const actualizarProceso = async (
  idResPrecalifica,
  idUsuario,
  subirSolicitud = null,
  solicitudAceptada = null,
  documentosVivienda = null,
  institucionExterna = null
) => {
  let dataProceso = {
    idUsuario: idUsuario,
    idResPrecalifica: idResPrecalifica,
    subirSolicitud: subirSolicitud,
    solicitudAceptada: solicitudAceptada,
    documentosVivienda: documentosVivienda,
    institucionExterna: institucionExterna,
  };

  let guardarProceso = await put("updateProceso", dataProceso);
  if (guardarProceso.statuscode === 200)
    console.log("Proceso actualizado");
};

export const guardarProceso = async (idResPrecalifica, idUsuario) => {
  const params = {
    idResPrecalifica: parseInt(idResPrecalifica),
    idUsuario: parseInt(idUsuario),
    subirSolicitud: 0,
    solicitudAceptada: 0,
    documentosVivienda: 0,
    institucionExterna: 0,
  };

  let insertProceso = await post("addProceso", params);
};

export const desencriptarToken = async (token) => {
  let desencriptar = await get("desencriptarToken", token);
  if (desencriptar.statuscode === 200) {
    return desencriptar.token;
  } else {
    return false;
  }
};

export const obtInformacionUsuarioRemax = async (idUsuario) => {
  let user = await get("obtInformacionUsuarioRemax", idUsuario);
  if (user.statuscode === 200) {
    return user.data;
  } else {
    return false;
  }
};

export const obtInformacionUsuarioSocioRemax = async (idUsuario) => {
  let user = await get("obtInformacionUsuarioSocioRemax", idUsuario);
  if (user.statuscode === 200) {
    console.log(user.data);
    return user.data;
  } else {
    return false;
  }
};

export const obtEmpresaUrl = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // Obtener la URL actual
      var urlPrueba = window.location.host;

      // Eliminar el prefijo "www." si está presente
      urlPrueba = urlPrueba.replace(/^www\./, '');

      // console.log("Url Host:", urlPrueba);

      let empresa = await get("obtConfigByNombreHost", urlPrueba);

      if (empresa.statuscode === 200) {
        localStorage.setItem("idEmpresaSocio", empresa.configuracion.idEmpresaSocio);
        localStorage.setItem("idEmpresaUsuarioDefault", empresa.usuarioDefault.idSocios);
        
        const idEmpresaSocio = empresa.configuracion.idEmpresaSocio;
        const img = empresa.configuracion.img;

        localStorage.setItem("logoEmpresa", img);

        await configurarEmpresa(empresa, idEmpresaSocio, img);

        resolve(empresa);

      }else{
        reject(undefined);
      }
    } catch (error) {
      // console.error("Error al obtener/configurar empresa:", error);
      reject(error); // Rechaza la promesa para que pueda ser manejada en otros lugares si es necesario
    }
  });
};

const obtEmpresaByNombreUrl = async (nombreHost) => {
  try {
    let empresa = await get("obtConfigByNombreHost", nombreHost);

    if (empresa.statuscode === 200) {

      // 16-02-2024 Capturamos el id de la empresa
      localStorage.setItem("idEmpresaSocio", empresa.configuracion.idEmpresaSocio);
      localStorage.setItem("idEmpresaUsuarioDefault", empresa.usuarioDefault.idSocios);


      const idEmpresaSocio = empresa.configuracion.idEmpresaSocio;
      const idImg = empresa.configuracion.img;
      let imagenLoading = '';

      if (idEmpresaSocio !== undefined && idImg !== undefined) {
        localStorage.setItem("logoEmpresa", idImg);
        configurarEmpresa(empresa, idEmpresaSocio, idImg);
      } else {
        // console.error("Valores de 'idEmpresaSocio' o 'img' no definidos.");
      }

      return empresa;
    } else {
      throw new Error(
        // `Error obteniendo empresa. Código de estado: ${empresa.statuscode}`
      );
    }
  } catch (error) {
    // console.error(error);
    return false;
  }
};

const waitForResourcesToLoad = () => {
  return new Promise((resolve) => {
    if (document.readyState === 'complete') {
      // Si el documento ya está completamente cargado, resuelve la promesa inmediatamente
      resolve();
    } else {
      // Si el documento no está completamente cargado, espera el evento load
      window.addEventListener('load', resolve);
    }
  });
};

const configurarEmpresa = async (empresa, idEmpresaSocio, idImg) => {
  // await waitForResourcesToLoad();
  const loading_saturn = await import('../images/Loading-Saturn.gif');
  const loading_remax = await import('../images/Loading-Finmax.gif');
  const loading_mCorporativo = await import('../images/loading_mCorporativo.gif');
  const loading_infonavit = await import('../images/loading_mCorporativo.gif');
  const loading_colwell = await import('../images/Loading-Finmax.gif');
  const loading_archandel = await import('../images/loadingicon.gif');
  const loading_opera = await import('../images/loadingicon.gif');
  const configuracion = empresa.configuracion;
  const confiAdicional = empresa.confiAdicional;

  localStorage.setItem('empresaId', idEmpresaSocio);

  let redesClaseHeader = '';
  let redesClaseFooter = '';
  let imagenLoading = '';

  const redesClases = {
    6: { header: '.redes-remax', footer: '.redes-footerFinmax', loading: loading_remax.default },
    1002: { header: '.redes-Consultores', footer: '#redes-footerConsultores', loading: loading_mCorporativo.default },
    1003: { header: '.redes-saturn', footer: '#redes-footerSaturn', loading: loading_saturn.default }, 
    1004: { loading: loading_infonavit.default }, 
    1005: { loading: loading_colwell.default },
    5: {  header: '.redes-archandel', footer:'#redes-footerArchandel', loading: loading_archandel.default },
    1006: {  header: '.redes-opera', footer:'#redes-footerOpera', loading: loading_opera.default }
  };

  if (redesClases[idEmpresaSocio]) {
    redesClaseHeader = redesClases[idEmpresaSocio].header;
    redesClaseFooter = redesClases[idEmpresaSocio].footer;
    imagenLoading = redesClases[idEmpresaSocio].loading;

    empresa.imagenLoading = imagenLoading;
    // console.log(empresa.imagenLoading); // Verifica la ruta de la imagen

    if (confiAdicional.redes_sociales === 1) {

      if(redesClaseHeader){
        if (redesClaseHeader.trim() !== ""){
          const redesHeader = document.querySelector(redesClaseHeader);
          if (redesHeader) {
            redesHeader.style.display = 'block';
          }
        }
      }
        
      if(redesClaseFooter){
        if (redesClaseFooter.trim() !== ""){
          const redesFooter = document.querySelector(redesClaseFooter);
          if (redesFooter) {
            redesFooter.style.display = 'block';
          }
        }
      }
    }
  }

  if (confiAdicional.ocultarcontacto === 1) {
    const ocultarContacto = document.querySelector('.ocultar_contacto');
    if (ocultarContacto) {
      ocultarContacto.style.display = 'none';
    }
  }

  document.documentElement.style.setProperty('--fondoCalculadora', confiAdicional.fondoCalculadora);
  document.documentElement.style.setProperty('--textoCalculadora', confiAdicional.colortexto_calculadora);
  document.documentElement.style.setProperty('--ColorBoton', configuracion.colorPrimario);
  document.documentElement.style.setProperty('--ColorGrafica', configuracion.colorPrimario);
  document.documentElement.style.setProperty('--botonSwal', confiAdicional.botonSwal);
  document.documentElement.style.setProperty('--ColorTexto', configuracion.colorSecundario);
  document.documentElement.style.setProperty('--FuentePrincipal', confiAdicional.fuentePrimaria);
  document.documentElement.style.setProperty('--FuenteSecundaria', confiAdicional.fuenteSecundaria);
  document.documentElement.style.setProperty('--ColorHoverBtn', confiAdicional.colorHoverBtn);
  document.documentElement.style.setProperty('--ColorAlternativo', confiAdicional.colorAlternativo ?? confiAdicional.colortexto_calculadora);

  if (confiAdicional.logoEmpresa === 1) {
    const isotipoElement = document.querySelector('.isotipo img');

    if (isotipoElement && idImg) {
      isotipoElement.src = idImg;
      isotipoElement.parentElement.className = 'isotipo_remax';
      
      if(empresa.configuracion.nombre.toLowerCase() == 'saturn5'){
        isotipoElement.style.width = '53px';
        isotipoElement.style.paddingRight  = '20px';
        const logoAdicional = document.querySelector('.logo_adicional');
        if(logoAdicional){
          logoAdicional.src = empresa.confiAdicional.logo_adicional;
        }

      }
    }

    const logoSaturnElement = document.querySelector('.ocultar_logoSaturn');
    if (logoSaturnElement && empresa.configuracion.nombre.toLowerCase() != 'saturn5') {
      //console.log("aqui");
      logoSaturnElement.style.display = 'none';
    }else{
      //console.log(idImg);
      const logoSaturnElement = document.querySelector('.ocultar_logoSaturn img');
      logoSaturnElement.src = empresa.confiAdicional.logo_adicional;
    }
  }

};


const obtenerConfiguraciones = async () => {
  // Obtener el valor almacenado en localStorage
  const empresaId = localStorage.getItem("empresaId");

  // Hacer algo con el valor obtenido
  if (empresaId) {
    // ("ID de la empresa:", empresaId);
  }
};

export const dispararCorreoPreRegistro = async (telefono) => {
  let nombre = localStorage.getItem("nombreCalculadora");
  let paterno = localStorage.getItem("apellidoPCalculadora");
  let materno = localStorage.getItem("apellidoMCalculadora");
  let correo = localStorage.getItem("correoCalculadora");
  let idEmpresa = localStorage.getItem("idEmpresaSocio");
  let enganche = localStorage.getItem("enganche");
  let montoSolicitado = localStorage.getItem("monto");

  let nombreCompleto = nombre + " " + paterno + " " + materno;

  let dataCorreo =
  {
    nombre: nombreCompleto,
    correo: correo,
    idEmpresa: idEmpresa,
    enganche: enganche,
    montoSolicitado: montoSolicitado,
    telefono: telefono
  };

  let enviarCorreo = await post("enviarCorreoPreRegistro", dataCorreo);

  if (enviarCorreo.statuscode === 200) console.log("Correo enviado");
}

//La llave es el idCatPAntalla de la tabla CatPantallas
export const pantallasFlujoBanco = {
  1: {paso: 1, pantalla: "descargarSolicitud"},
  2: {paso: 2, pantalla: "solicitudAceptada"},
  3: {paso: 3, pantalla: "documentosVivienda"},
  4: {paso: 4, pantalla: "avaluo"},
  11: {paso: 5, pantalla: "notariaAsignada"},
  5: {paso: 6, pantalla: "institucionExterna"},
  6: {paso: 7, pantalla: "formalizacionFirma"},
  7: {paso: 8, pantalla: "felicidades"},
}


export const renderGoogleAuthButton = (authCallback, idElement) => {
  const script = document.createElement('script');
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => {
    window.google.accounts.id.initialize({
      client_id: "576877665667-7h4tj53aa0bj3r7ser2lgqgc8vr76cuc.apps.googleusercontent.com",
      callback: authCallback,
      auto_select: false,
    });

    window.google.accounts.id.renderButton(
      document.getElementById(idElement),
      { theme: "outline", logo_alignment: "left", size: "large", width: "100px", shape: "pill", locale: "es-419" }
    );
  };

  return () => {
    document.body.removeChild(script);
  };
}