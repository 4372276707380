import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ruleEmail } from "../assets/validateRules";
import { post } from "../utils/http";
import { obtEmpresaUrl, renderGoogleAuthButton } from "../utils/global_functions";
import "../App.css";
import "../sass/style.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import login from "../images/login.jpg";

import { useLocation } from "react-router-dom";

export function Raiz({ children }) {
  const [isLoading, setIsLoading] = useState(true);


  useEffect( () =>  {

    const cargarDatos = async () => {
      try {
        let resEmpresa = await obtEmpresaUrl();
        // console.log(resEmpresa);
        if(resEmpresa.statuscode === 200){
          setIsLoading(false);
        }

      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
  
    cargarDatos();
  }, []);
  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <Header />
      {children}
      <Footer />
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------ Pantalla: Index/Iniciar Sesión ------------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export function Index() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const cookie = new Cookies();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function loadingPop(){
    let timerInterval;
    Swal.fire({
      title: "Iniciando sesión",
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.fire({
          title: "Upss...",
          confirmButtonText: "Aceptar",
          icon: "info",
          html: "<b>El tiempo de espera ha sido superado, intentalo más tarde...</b>",
        });
      }
    });
  }

  const onSubmit = () => {
    loadingPop();
    signIn();
  };

  const signIn = async () => {
    let data = {
      usaurio: {
        correo: email,
      },
      password: {
        password1: password,
      },
    };

    let response = await post("login", data);
    if (response.statuscode === 200) {
      cookie.set("session", true, { path: "/" });
      cookie.set("nombre", response.nombre, { path: "/" });
      cookie.set("correo", response.correo, { path: "/" }); //TODO: Posiblemente correo ath o jwt
      cookie.set("id", response.id, { path: "/" });

      window.location.href = queryParams.get("redirect") ? queryParams.get("redirect") : "/home";
    } else {
      Swal.fire({
        title: "Usuario y/o contraseña erronea!",
        text: "Verifica que la cuenta y la contraseña sea correcta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const googleSignIn = async (respuesta) => {
    //console.log(respuesta);
    let idSocios = null;
    if (localStorage.getItem("idSocioHash") != null) {
      idSocios = localStorage.getItem("idSocioHash");
    } else {
      idSocios = localStorage.getItem("idEmpresaUsuarioDefault");
    }

    let peticion = await post("googleAuth", {
      token: respuesta?.credential,
      idSocios: idSocios
    });

    if (peticion.statuscode === 200) {
      cookie.set("session", true, { path: "/" });
      cookie.set("nombre", peticion.respuesta.nombre, { path: "/" });
      cookie.set("correo", peticion.respuesta.correo, { path: "/" });
      cookie.set("id", peticion.respuesta.id, { path: "/" });
      
      window.location.href = queryParams.get("redirect") ? queryParams.get("redirect") : "/home";
    } else {
      Swal.fire({
        title: "Error de autenticación!",
        text: "Iniciar sesión con otro método",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  function verPassword() {
    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = "fa fa-eye-slash iconoOcultarPassword";
    } else {
      x.type = "password";
      icono.className = "fa fa-eye iconoOcultarPassword";
    }
  }

  useEffect(() => {
    obtEmpresaUrl();
    renderGoogleAuthButton((respuesta) => {
      googleSignIn(respuesta);
      loadingPop();
    }, "contenedorGoogleAuth");

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>Accede a tu cuenta</h1>
          <p>
            Crea tu cuenta y comienza a hacer uso de los beneficios de
            pertenecer a nuestra comunidad.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              value={email}
              onInput={(ev) => {
                setEmail(ev.target.value);
              }}
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            {errors.email && (
              <span class="span"> El formato del correo no es valido</span>
            )}
            <label htmlFor="password">Contraseña </label>
            <div className="relativePosition">
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                {...register("password", { required: true })}
                onInput={(ev) => {
                  setPassword(ev.target.value);
                }}
              />
              <i
                id="iconoPassword"
                className="fa fa-eye iconoOcultarPassword"
                onClick={() => {
                  verPassword();
                }}
              >
                {" "}
              </i>
            </div>
            {errors.password?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            <div className="botones">
              <button type="submit" className="btn btn_morado" id="btnIniciar">
                Iniciar sesión
              </button>
              <Link className="btn btn_bco" to={"/crearcuentaSinSimulacion" + (queryParams.get("redirect") ? `?redirect=${queryParams.get("redirect")}` : "" )}>
                Crear cuenta
              </Link>
            </div>
            <div className="flex_centrado">
              <Link to="/recuperapass" className="link">
                Olvidé mi contraseña
              </Link>
            </div>
          </form>
          <br/>

          <div className="flex_centrado">
            <div id="contenedorGoogleAuth"></div>
          </div>
        </div>

        <div className="col6 cont_img-index">
          <img src={login} alt="Login" />
        </div>
      </div>

    </>
  );
}
