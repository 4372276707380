import "../../App.css";
import { useState, useEffect } from "react";
import { post } from "../../utils/http";
import "react-tooltip/dist/react-tooltip.css";
import { round } from "../../utils/operaciones_formulario";
import { ModalFormulario } from "../../components/Modals";
import Swal from "sweetalert2";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------- Pantalla: Calculadora web para crear nueva cotización ------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function CalculadoraWebFirmauto() {
  // VALORES PARA EL CÁLCULO 
  const CUOTA_INSC_INICIAL = 0.015;       // Cuota inscripción inicial: 1.5%
  const CUOTA_INSC_DIFERIDA = 0.03;       // Cuota de inscripción diferida: 3.0%
  const CUOTA_ADMINISTRACION = 0.004;     // Cuota de Administración: 0.4%

  const SEGURO_VIDA = 0.00075;                              // Seguro de vida: 0.075%
  const SEGURO_DANIOS = 0.00025;                            // Seguro de daños: 0.025%
  const PLAZO = 180;                                        // Plazo (meses)
  const PAGOS_TOTALES_ADJUDICACION = {val: 45, por: 0.25};  // Pagos totales para adjudicacion: 45 , 25%
  const PLAZO_MINIMO_PERMANENCIA = {val: 9, por: 0.05};     // Plazo minimo de permanencia: 9, 5%

  const [pagoInicial, setPagoInicial] = useState("-");
  const [pagosMensuales, setPagosMensuales] = useState("-");
  const [pagosMinimos, setPagosMinimos] = useState("-");
  const [tiempoMinimo, setTiempoMinimo] = useState(6);

  const [valor, setValor] = useState(500000);
  const [valorComas, setValorComas] = useState("500,000");

  const [inmueble5, setInmueble5] = useState(true);

  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [btnDeshabilitado, setBtnDeshabilitado] = useState(true);

 
  function ocultarHeaderFooter() {
    const headerElement = document.querySelector("header");
    if (headerElement) {
      headerElement.style.display = "none";
    }

    const headerResponsiveElement = document.querySelector("div.header_responsive");
    if (headerResponsiveElement) {
      headerResponsiveElement.style.display = "none";
    }

    const footerElement = document.querySelector("footer");
    if (footerElement) {
      footerElement.style.display = "none";
    }
  }

  const calcularSimulacion = (valorInmueble) => {
    let montoAContratar = valorInmueble * 1.12305;
  
    // Aportación periódica = monto a contratar / Plazo (meses)
    let apPeriodica = montoAContratar / PLAZO;
    // Seguro de vida = monto a contratar * seguro de vida (CONSTANTE)
    let seguroVida = montoAContratar * SEGURO_VIDA;
    // Cuota de administración = monto a contratar * cuota de administración * 1.16
    let cuotaAdministracion = montoAContratar * CUOTA_ADMINISTRACION * 1.16;

    // Cuota de inscripción = monto a contratar * cuota de inscripción inicial * 1.16
    let cuotaInscripcion = montoAContratar * CUOTA_INSC_INICIAL * 1.16;

    // Pagos mínimos para estrenar casa = monto a contratar * pagos totales para adjudición (%)
    let pagosMinimos = montoAContratar * PAGOS_TOTALES_ADJUDICACION.por;
    // Aportación mensual = aportación periodica + seguro de vida + cuota de administración
    let apMensual = apPeriodica + seguroVida + cuotaAdministracion;
    // Pago inicial = aportación mensual + cuota de inscripción
    let pagoInicial = apMensual + cuotaInscripcion;

    setPagosMinimos(round(pagosMinimos, 0));
    setPagosMensuales(round(apMensual, 0));
    setPagoInicial(round(pagoInicial, 0));
    setBtnDeshabilitado(false);

  }

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    ocultarHeaderFooter();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <div id="mainDivCalculadora" className="cont_flex strech calc_firmauto">
        {
          modalFormVisible && (
            <ModalFormulario 
              onClose={() => {
                setModalFormVisible(false);
              }}
              onSend={async (formDatos) => {

                //console.log(formDatos);

                const res = await post("enviarCorreoCalculadoraFirmauto", {
                  idEmpresaSocio: localStorage.getItem("idEmpresaSocio"),
                  usuario: {
                    nombre: formDatos.nombre,
                    correo: formDatos.email,
                    telefono: formDatos.telefono
                  },
                  simulacion: {
                    valorInmueble: valor,
                    pagoInicial: pagoInicial,
                    pagosMensuales: pagosMensuales,
                    pagosMinimos: pagosMinimos,
                    tiempoMinimo: tiempoMinimo
                  }
                });

                //console.log("RESPUESTA:", res);
                if(res.statuscode === 200){
                  Swal.fire({
                    title: "Correo enviado",
                    text: "Espera nuestra respuesta, nos pondremos en contacto contigo.",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  });
                  setModalFormVisible(false);
                }else{
                  Swal.fire({
                    title: "No se pudo enviar el correo",
                    text: "Intente más tarde",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                  });
                }
                
              }}
            />
          )
        }
        <div className="col6 simulador">
          <h2>Simula tu crédito</h2>
          <p>
            Descubre cuánto pagarías al mes por vivir en tu casa ideal. Al
            usar nuestro simulador, podrás conocer todo lo que necesitas
            para conseguir un financiamiento a tu medida.
          </p>
          <div className="precio_cotizar">
            <label>Valor de Inmueble</label>
            <p>
              $
              <input
                className="txtRight"
                type="tel"
                name="txtPrecio"
                id="txtPrecio"
                value={valorComas}
                onChange={(ev) => {
                  setValor(
                    parseInt(
                      ev.target.value.replaceAll(".", "").replaceAll(",", "")
                    )
                  );
                  let sueldo = ev.target.value;
                  sueldo = sueldo.replaceAll(",", "");
                  sueldo = sueldo.replace(" MXN", "");
                  sueldo = sueldo.replace(" MX", "");
                  sueldo = sueldo.replace(" M", "");
                  sueldo = sueldo.replaceAll(" ", "");
                  sueldo = sueldo.replace("$", "");
                  if (sueldo === 0 || sueldo === "") {
                    setValorComas("");
                    setInmueble5(false);
                  } else {
                    if (sueldo >= 500000) {
                      setInmueble5(true);
                    } else {
                      setInmueble5(false);
                    }
                    sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                    if (sueldo !== "NaN") {
                      setValorComas(sueldo);
                    }
                  }
                  calcularSimulacion(parseInt(
                    ev.target.value.replaceAll(".", "").replaceAll(",", "")
                  ));
                }}
              />
              <span>MXN</span>
            </p>
            {inmueble5 === false && (
              <small class="span">
                El precio del inmueble debe ser mayor o igual a $500,000 MXM{" "}
              </small>
            )}
          </div>
          
        </div>
        <div className="col6 resultados bg_morado2">
          <h3>Resultados de tu simulación</h3>
          <ul>
            <li>
              Pago inicial
              <span id="spanFinaciamientoVal">${GetNF(pagoInicial)}</span>
              &nbsp;MXN
            </li>
            <li>
              Pagos mensuales* <span id="spanMensualidad">${GetNF(pagosMensuales)}</span>
              &nbsp;MXN
            </li>
            <li>
              Pagos mínimos para estrenar tu casa**{" "}
              <span id="spanSueldo">${GetNF(pagosMinimos)}</span>
              &nbsp;MXN
            </li>
            <li>
              Tiempo mínimo para estrenar tu casa <span id="spanFpmVal">{tiempoMinimo} meses</span>
            </li>
            <li className="cont_btn_firmauto">
              <button className="btn_morado_personalizado bg_alternativo btn_max_width"
                onClick={() => {
                  setModalFormVisible(true);
                }}
                disabled={btnDeshabilitado || !inmueble5}
              >
                Da click para lograr tu sueño
              </button>
            </li>
          </ul>
          <small>* Los valores mostrados son un estimado</small>
          <small>
            ** No es necesario contar con este monto como enganche, haremos un plan adecuado para ti.
          </small>
        </div>
      </div>
      <br />
    </>
  );
}
