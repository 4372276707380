import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import * as ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ruleCP,
  ruleEmail,
  ruleRFC,
  ruleTelefono,
  ruleNacimiento,
} from "../../assets/validateRules";
import { set, useForm } from "react-hook-form";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoLaboral() {
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  // Estado para almacenar la información laboral.
  const [infoLaboral, setInfoLaboral] = useState({});
  // Estados para validar reglas de teléfono y fecha de nacimiento.
  const [validarReglaTelefono, setValidarReglaTelefono] = useState();
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState();
  const [validarIngresoOpcional, setValidarIngresoOpcional] = useState();
  const [validarSalidaOpcional, setValidarSalidaOpcional] = useState();

  const [validarLongitudCP, setValidarLongitudCP] = useState();
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [listColonias, setListColonias] = useState();

  // Estados para almacenar información de dirección.
  const [direccion, setDireccion] = useState("");
  const [exterior, setExterior] = useState("");
  const [interior, setInterior] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoAux, setEstadoAux] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");
  const [error, setError] = useState(null);

  //Para almacenar informacion del empleo
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [puesto, setPuesto] = useState("");
  const [ocupacion, setOcupacion] = useState("");
  const [tipoEmpleo, setTipoEmpleo] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [giroEmpresa, setGiroEmpresa] = useState("");

  //Para almacenar el empleo anterior
  const [nombreEmpresaAnt, setNombreEmpresaAnt] = useState("");
  const [puestoAnt, setPuestoAnt] = useState("");
  const [ocupacionAnt, setOcupacionAnt] = useState("");
  const [tipoEmpleoAnt, setTipoEmpleoAnt] = useState("");
  const [telefonoAnt, setTelefonoAnt] = useState("");
  const [fechaIngresoAnt, setFechaIngresoAnt] = useState("");
  const [fechaSalidaAnt, setFechaSalidaAnt] = useState("");

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  // Función para dar formato a una entrada de fecha.
  const DateInput = (event, tipo, setValidarRegla) => {
    let input = event;

    // Remover las diagonales existentes
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }

    if (tipo === 1) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaIngreso: input }));
      setFechaIngreso(input);
      setValidarRegla(ruleNacimiento(input));
    } else if (tipo === 2) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaIngresoAnt: input }));
      setFechaIngresoAnt(input);
      setValidarRegla(ruleNacimiento(input) || input === "");
    } else if (tipo === 3) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaSalidaAnt: input }));
      setFechaSalidaAnt(input);
      setValidarRegla(ruleNacimiento(input) || input === "");
    }
    

    let fechaIngresoNF =
      input.substr(6, 4) + "-" + input.substr(3, 2) + "-" + input.substr(0, 2);
  };

  // Función para obtener datos de código postal.
  const obtenerDataCp = async (cp) => {
    try {
      let respuestaPorCP = await getCP("info_cp", cp);
      console.log(respuestaPorCP);
      setError(false);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setMunicipio(respuestaPorCP.response.municipio);
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    } catch (error) {
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setMunicipio("");
      setError(true);
    }
  };

  // Función para obtener la clave ISO del estado.
  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  // Función para listar colonias.
  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    setListColonias(colonias);
  };

  //Obtenemos la informacion laboral
  //Si es que existe
  const obtInfoLaboral = async () => {
    //*Hace una solicitud a la función get para obtener información laboral utilizando un ID
    //*que se obtiene de las cookies (cookies.get('id')).
    let data = await get("getInfoLaboral", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      //* Si la respuesta contiene una fecha de ingreso (fechaIngreso), la función convierte
      //* esa fecha en un formato específico (aaaa-mm-dd) y luego en otro formato (dd/mm/aaaa)
      //* antes de almacenarla nuevamente en el objeto data.infoLaboral[0].fechaIngreso.
      if (data.infoLaboral[0].fechaIngreso) {
        let fechaIngreso = new Date(data.infoLaboral[0].fechaIngreso);

        if (fechaIngreso.getMonth() + 1 < 10 && fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        } else {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        }
        let fechaIN =
          data.infoLaboral[0].fechaIngreso.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(0, 4);
        data.infoLaboral[0].fechaIngreso = fechaIN;
        setFechaIngreso(fechaIN);
        setValue("fechaIngreso", fechaIN);
      }

      if (data.infoLaboral[0].fechaIngresoAnt) {
        let fechaIngresoAnt = new Date(data.infoLaboral[0].fechaIngresoAnt);

        if (
          fechaIngresoAnt.getMonth() + 1 < 10 &&
          fechaIngresoAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getDate() < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        } else {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaIngresoAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(0, 4);
        data.infoLaboral[0].fechaIngresoAnt = fechaIN;
        setFechaIngresoAnt(fechaIN);
        setValue("fechaIngresoAnt", fechaIN);
      }

      if (data.infoLaboral[0].fechaSalidaAnt) {
        let fechaSalidaAnt = new Date(data.infoLaboral[0].fechaSalidaAnt);

        if (
          fechaSalidaAnt.getMonth() + 1 < 10 &&
          fechaSalidaAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getDate() < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        } else {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaSalidaAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(0, 4);
        data.infoLaboral[0].fechaSalidaAnt = fechaIN;
        setFechaSalidaAnt(fechaIN);
        setValue("fechaSalidaAnt", fechaIN);
      }

      //* Establece varios estados del componente (setEstado, setMunicipio, setColonia, setCp,
      //* setDireccion, setExterior, setInterior) con los valores correspondientes de la
      //* información laboral obtenida.
      setEstadoAux(data.infoLaboral[0].municipio);
      setValue("estado", data.infoLaboral[0].estado);

      setMunicipio(data.infoLaboral[0].municipio);
      setValue("municipio", data.infoLaboral[0].municipio);

      setError(true);
      setColonia(data.infoLaboral[0].colonia);
      setValue("colonia", data.infoLaboral[0].colonia);

      setCp(data.infoLaboral[0].cp);
      setValue("cp", data.infoLaboral[0].cp, ruleCP);

      setDireccion(data.infoLaboral[0].calle);
      setValue("direccion", data.infoLaboral[0].calle);

      setExterior(data.infoLaboral[0].numExt);
      setValue("numExt", data.infoLaboral[0].numExt);

      setInterior(data.infoLaboral[0].numInt);
      setValue("numInt", data.infoLaboral[0].numInt);

      setNombreEmpresa(data.infoLaboral[0].nombreEmpresa);
      setValue("nombreEmpresa", data.infoLaboral[0].nombreEmpresa);

      setPuesto(data.infoLaboral[0].puesto);
      setValue("puesto", data.infoLaboral[0].puesto);

      setOcupacion(data.infoLaboral[0].ocupacion);
      setValue("ocupacion", data.infoLaboral[0].ocupacion);

      setTipoEmpleo(data.infoLaboral[0].tipoEmpleo);
      setValue("tipoEmpleo", data.infoLaboral[0].tipoEmpleo);

      setTipoContrato(data.infoLaboral[0].tipoContrato);
      setValue("tipoContrato", data.infoLaboral[0].tipoContrato);

      setTelefono(data.infoLaboral[0].telefono);
      setValue("telefono", data.infoLaboral[0].telefono);

      setGiroEmpresa(data.infoLaboral[0].giro);
      setValue("giroEmpresa", data.infoLaboral[0].giro);

      setNombreEmpresaAnt(data.infoLaboral[0].nombreEmpresaAnt);
      setPuestoAnt(data.infoLaboral[0].puestoAnt);
      setOcupacionAnt(data.infoLaboral[0].ocupacionAnt);
      setTipoEmpleoAnt(data.infoLaboral[0].tipoEmpleoAnt);
      setTelefonoAnt(data.infoLaboral[0].telefonoAnt);

      //* Utiliza setInfoLaboral para actualizar el estado del componente. Aquí se usa el
      //* operador spread (...) para mezclar los datos anteriores de infoLaboral con los
      //* nuevos datos de data.infoLaboral[0]. Esto se hace para actualizar el estado del
      //* componente con la información laboral completa.
      setInfoLaboral(() => ({
        ...infoLaboral,
        ...data.infoLaboral[0],
      }));
    } else {
      console.log("Sin informacion guardada previamente");
    }
  };

  const onSubmit = (data) => {
    //console.log(data); // Aquí puedes hacer algo con los datos del formulario
    guardarInformacionLaboral();
  };

  function formatearFechaGuion(fecha){
    const fechaArray = fecha.split("/");
    return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
  }

  const guardarInformacionLaboral = async () => {

    let dataInfoLaboral = {
      idUser: cookies.get("id"),
      nombreEmpresa: nombreEmpresa,
      puesto: puesto,
      telefono: telefono,
      fechaIngreso: formatearFechaGuion(fechaIngreso),
      giro: giroEmpresa,
      calle: direccion,
      numExt: exterior,
      numInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      nombreEmpresaAnt: nombreEmpresaAnt,
      puestoAnt: puestoAnt,
      telefonoAnt: telefonoAnt,
      idResPrecalifica: idResPrecalifica,
      estado: estadoAux,
      ocupacion: ocupacion,
      tipoEmpleoAnt: tipoEmpleoAnt,
      ocupacionAnt: ocupacionAnt,
      tipoEmpleo: tipoEmpleo,
      tipoContrato: tipoContrato,
      escoAcreditado: false,
    };

    if (fechaIngresoAnt !== null && fechaIngresoAnt !== ""){
      dataInfoLaboral["fechaIngresoAnt"] = formatearFechaGuion(fechaIngresoAnt);
    }

    if (fechaSalidaAnt !== null && fechaSalidaAnt !== ""){
      dataInfoLaboral["fechaSalidaAnt"] = formatearFechaGuion(fechaSalidaAnt);
    }

    let consultarInfo = await get("getInfoLaboral", cookies.get("id"));
    if (consultarInfo.msg === "El usuario no tiene informacion laboral") {
      let guardarInfoLaboral = await post("addInfoLaboral", dataInfoLaboral);
      if (guardarInfoLaboral.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setTimeout(() => {
          window.location.href = "/informacion-general";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (consultarInfo.statuscode === 200) {
        let actInfoLaboral = await put("updateInfoLaboral", dataInfoLaboral);

        if (actInfoLaboral.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          setTimeout(() => {
            window.location.href = "/informacion-general";
          }, 1000);
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    obtInfoLaboral();
    obtInfoCuestionario();
  }, []);

  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion-general">
          {"<"} Regresar a documentación
        </a>

        <h1 className="titt">Información Laboral​</h1>
        <p>
          Asegurate de poner la información correcta. <br /> Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="Resum">
            <div className="header">
              <h3>Empleo actual</h3>
            </div>
            <div className="cont_flex cont-form formInfoGeneral">
              {/* NOMBRE */}
              <div className="col3 campo-form ">
                <label>Nombre de la empresa *</label>
                <input
                  type="text"
                  {...register("nombreEmpresa", { required: true })}
                  autoComplete="off"
                  id="nombreEmpresa"
                  name="nombreEmpresa"
                  value={nombreEmpresa || ""}
                  onInput={(ev) => {
                    setNombreEmpresa(
                      ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
                {errors.nombreEmpresa?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Puesto*/}
              <div className="col3 campo-form">
                <label>Puesto *</label>
                <input
                  type="text"
                  {...register("puesto", { required: true })}
                  id="puesto"
                  name="puesto"
                  value={puesto || ""}
                  onInput={(ev) => {
                    setPuesto(
                      ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
                {errors.puesto?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Ocupación */}
              <div className="col3 campo-form">
                <label>Ocupación *</label>
                <input
                  type="text"
                  {...register("ocupacion", { required: true })}
                  id="ocupacion"
                  name="ocupacion"
                  value={ocupacion || ""}
                  onInput={(ev) => {
                    setOcupacion(
                      ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
                {errors.ocupacion?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Tipo de empleo*/}
              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de empleo *</label>
                <select
                  {...register("tipoEmpleo", { required: true })}
                  id="tipoEmpleo"
                  name="tipoEmpleo"
                  value={tipoEmpleo || ""}
                  onChange={(ev) => {
                    setTipoEmpleo(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Empleado</option>
                  <option value="2">Negocio propio</option>
                  <option value="3">Otro</option>
                </select>
                {errors.tipoEmpleo?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de contrato *</label>
                <select
                  {...register("tipoContrato", { required: true })}
                  id="tipoContrato"
                  name="tipoContrato"
                  value={tipoContrato || ""}
                  onChange={(ev) => {
                    setTipoContrato(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Definitivo (no temporal)</option>
                  <option value="2">Temporal</option>
                </select>
                {errors.tipoContrato?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Teléfono*/}
              <div className="col3 campo-form">
                <label>Teléfono *</label>
                <input
                  type="tel"
                  {...register("telefono", { required: true })}
                  id="telefono"
                  name="telefono"
                  size="14"
                  maxLength={10}
                  placeholder="Teléfono a 10 dígitos"
                  autoComplete="off"
                  value={telefono || ""}
                  onInput={(ev) => {
                    setValidarReglaTelefono(ruleTelefono(ev.target.value));
                    setTelefono(() => ev.target.value);
                  }}
                />
                {errors.telefono?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
                {validarReglaTelefono === false && (
                  <small className="span"> Ingresar solo números</small>
                )}
              </div>

              {/* Fecha de ingreso*/}
              <div className="col3 campo-form">
                <label>Fecha de ingreso *</label>
                <input
                  type="text"
                  {...register("fechaIngreso", { required: true })}
                  id="fechaIngreso"
                  name="fechaIngreso"
                  value={fechaIngreso || ""}
                  placeholder="dd/mm/aaaa"
                  onInput={(ev) => {
                    DateInput(ev.target.value, 1, setValidarReglaNacimento);
                  }}
                />
                {validarReglaNacimiento === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa{" "}
                  </small>
                )}
                {errors.fechaIngreso?.type === "required" && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              {/* Giro de la empresa*/}
              <div className="col3 campo-form estado marginEspacio">
                <label>Giro de la empresa *</label>
                <select
                  {...register("giroEmpresa", { required: true })}
                  id="giroEmpresa"
                  name="giroEmpresa"
                  value={giroEmpresa || ""}
                  onInput={(ev) => {
                    setGiroEmpresa(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Servicios</option>
                  <option value="2">Construcción</option>
                  <option value="3">Industrial</option>
                  <option value="4">Agricultura / Pesca</option>
                  <option value="5">Otro</option>
                </select>
                {errors.giroEmpresa?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Dirección del empleo</h3>
            </div>
            <div className="cont_flex cont-form formInfoGeneral">
              {/* Código postal */}
              <div className="col3 campo-form ">
                <label htmlFor="cp">Código postal *</label>
                <input
                  type="tel"
                  id="cp"
                  name="cp"
                  size={5}
                  maxLength="5"
                  autoComplete="off"
                  value={cp}
                  {...register("cp", { required: true, validate: ruleCP })}
                  onInput={(ev) => {
                    let tempCP = ev.target.value;
                    if (isNaN(tempCP) === false) {
                      if (tempCP.length < 5) {
                        setValidarLongitudCP(false);
                        setCp(tempCP);
                      } else {
                        if (tempCP.length === 5) {
                          obtenerDataCp(ev.target.value);
                          setCp(tempCP);
                          setValidarLongitudCP(true);
                        }
                      }
                    }
                  }}
                />
                <small>
                  Al ingresar su código postal correcto, en automático se
                  registrará su estado y municipio.
                </small>
                {errors.cp?.type === "required" && (
                  <span className="span">
                    {" "}
                    <br />
                    Este campo es requerdio{" "}
                  </span>
                )}
                {validarLongitudCP === false && (
                  <span className="span"> Ingresa 5 dígitos</span>
                )}
              </div>

              {/* Direccion*/}
              <div className="col3 campo-form">
                <label>Dirección *</label>
                <input
                  type="text"
                  {...register("direccion", { required: true })}
                  autoComplete="off"
                  id="direccion"
                  name="direccion"
                  value={direccion || ""}
                  onInput={(ev) => {
                    setDireccion(
                      ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
                {errors.direccion?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Número Ext */}
              <div className="col3 campo-form">
                <label>Número Ext *</label>
                <input
                  type="tel"
                  name="numExt"
                  id="numExt"
                  autoComplete="off"
                  value={exterior || ""}
                  onInput={(ev) => {
                    setExterior(ev.target.value);
                  }}
                />
              </div>

              {/* Número Int */}
              <div className="col3 campo-form">
                <label>Número Int</label>
                <input
                  type="tel"
                  name="numInt"
                  id="numInt"
                  max={20}
                  autoComplete="off"
                  value={interior || ""}
                  onInput={(ev) => {
                    setInterior(ev.target.value);
                  }}
                />
              </div>

              {/* Estado*/}
              <div className="col3 campo-form estado">
                <label htmlFor="estado">Estado *</label>
                <input
                  type="text"
                  name="estado"
                  id="estado"
                  disabled={estadoDisabled}
                  value={estadoAux}
                  onInput={(ev) => {
                    setEstadoAux(ev.target.value);
                  }}
                />
              </div>

              {/* Delegación / Municipio*/}
              <div className="col3 campo-form colonia">
                <label htmlFor="municipio">Delegación / Municipio *</label>
                <input
                  name="municipio"
                  id="municipio"
                  disabled={delegacionDisabled}
                  value={municipio}
                  onInput={(ev) => {
                    setMunicipio(ev.target.value);
                  }}
                ></input>
              </div>

              {/* Colonia*/}
              <div className="col3 campo-form colonia">
                <label htmlFor="coloniaSelect">Colonia *</label>
                {error ? (
                  <input
                    type="text"
                    id="colonia"
                    value={colonia}
                    name="colonia"
                    onChange={(ev) => {
                      setColonia(ev.target.value);
                    }}
                  />
                ) : (
                  <select
                    type="text"
                    id="colonia"
                    value={colonia}
                    name="colonia"
                    onChange={(ev) => {
                      setColonia(ev.target.value);
                    }}
                  >
                    {listColonias}
                  </select>
                )}
              </div>
            </div>
          </div>

          {/*Campos opcionales no aplicar validaciones */}
          <div className="Resum">
            <div className="header">
              <h3>Empleo anterior (Opcional)</h3>
            </div>
            <div className="cont_flex cont-form">
              <div className="col3 campo-form">
                <label>Nombre de la empresa</label>
                <input
                  type="text"
                  id="nombreEmpresaAnt"
                  name="nombreEmpresaAnt"
                  value={nombreEmpresaAnt || ""}
                  onInput={(ev) => {
                    setNombreEmpresaAnt(
                      ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
              </div>

              <div className="col3 campo-form">
                <label>Puesto</label>
                <input
                  type="text"
                  id="puestoAnt"
                  name="puestoAnt"
                  value={puestoAnt || ""}
                  onInput={(ev) => {
                    setPuestoAnt(
                      ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
              </div>

              <div className="col3 campo-form">
                <label>Ocupación</label>
                <input
                  type="text"
                  id="ocupacionAnt"
                  name="ocupacionAnt"
                  value={ocupacionAnt || ""}
                  onInput={(ev) => {
                    setOcupacionAnt(
                      ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                />
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de empleo</label>
                <select
                  id="tipoEmpleoAnt"
                  name="tipoEmpleoAnt"
                  value={tipoEmpleoAnt || ""}
                  onInput={(ev) => {
                    setTipoEmpleoAnt(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Empleado</option>
                  <option value="2">Negocio propio</option>
                  <option value="3">Otro</option>
                </select>
              </div>

              <div className="col3 campo-form">
                <label>Teléfono</label>
                <input
                  type="tel"
                  size="14"
                  maxLength={10}
                  name="telefonoAnt"
                  id="telefonoAnt"
                  placeholder="Teléfono a 10 dígitos"
                  autoComplete="off"
                  value={telefonoAnt || ""}
                  onInput={(ev) => {
                    setValidarReglaTelefono(ruleTelefono(ev.target.value));
                    setTelefonoAnt(() => ev.target.value);
                  }}
                />
              </div>

              <div className="col3 campo-form">
                <label>Fecha de ingreso</label>
                <input
                  type="text"
                  value={fechaIngresoAnt || ""}
                  placeholder="dd/mm/aaaa"
                  name="fechaIngresoAnt"
                  id="fechaIngresoAnt"
                  onInput={(ev) => {
                    DateInput(ev.target.value, 2, setValidarIngresoOpcional);
                  }}
                />
                {validarIngresoOpcional === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa <br />
                  </small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Fecha de salida</label>
                <input
                  type="text"
                  value={fechaSalidaAnt || ""}
                  placeholder="dd/mm/aaaa"
                  name="fechaSalidaAnt"
                  id="fechaSalidaAnt"
                  onInput={(ev) => {
                    DateInput(ev.target.value, 3, setValidarSalidaOpcional);
                  }}
                />
                {validarSalidaOpcional === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa <br />
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <a
                className="btn btn_bco  campo-form "
                href="/informacion-general"
              >
                {" "}
                Regresar
              </a>
              <button type="submit" className="btn btn_morado campo-form">
                Guardar
              </button>
            </div>
          </div>
          <div className="header">
            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>
          {/* <Link to="/informacion/inf-personal" className="btn btn_morado">Continuar con información personal</Link> */}
        </form>
      </div>
    </>
  );
}
