import "../../App.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import Cookies from "universal-cookie";
import { useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { Tooltip } from "react-tooltip";
import { get } from "../../utils/http";
import "react-tooltip/dist/react-tooltip.css";
import { actualizarEstatusInvitacion } from "../../utils/global_functions";
import { round } from "../../utils/operaciones_formulario";
import gifClick from "../../images/tap.png";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------- Pantalla: Calculadora web para crear nueva cotización ------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function CalculadoraWeb() {
  const [logo, setLogo] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [mostrarLogo, setMostrarLogo] = useState(false);

  const cookie = new Cookies();
  const [valor, setValor] = useState(500000);
  const [valorComas, setValorComas] = useState("500,000");
  const [rango, setRango] = useState(10);
  const [enganche, setEnganche] = useState("50,000.00");
  const fpmValues = { P5: 22.04, P10: 14.26, P15: 11.93, P20: 10.9 };
  const relPagIng = 0.35;
  const [mp, setMp] = useState("-");
  const [pm, setPm] = useState("-");
  const [imb, setImb] = useState("-");
  const [tasa, setTasa] = useState("-");
  const [tiempo, setTiempo] = useState(0);
  const actionContext = useSetDataPublic();
  const [inmueble5, setInmueble5] = useState(true);
  const [rangoEngacheValido, setRangoEngacheValido] = useState(true);

  const [esperandoRespuesta, setEsperandoRespuesta] = useState(false);
  const [msgEspera, setMsgEspera] = useState(false);

  //!En caso de que exista un parametro en la url, se desencripta el id del socio
  const obtIdSocio = async () => {
    var queryString = window.location.search.substr(1);
    if (queryString !== "") {
      queryString = queryString.replace(/\+/g, " ");
      let desencriptar = await get("desencriptarInvitacion", queryString);
      
      if (desencriptar.statuscode === 200) {
        const cadena = desencriptar.idSocio;

        // Busca el primer número
        const primerNumero = cadena.match(/socio=(\d+)/);
        if (primerNumero) {
          const idSocio = primerNumero[1];
          localStorage.setItem("idSocioHash", idSocio);
          //console.log(idSocio);
          obtInfoSocio(idSocio);

          ocultarHeaderFooter();
        } else {
          // console.log("Número de socio no encontrado");
        }

        // Busca el segundo número
        const segundoNumero = cadena.match(/idInvitacion=(\d+)/);
        if (segundoNumero) {
          const idInvitacion = segundoNumero[1];
          localStorage.setItem("idInvitacionHash", idInvitacion);
          actualizarEstatusInvitacion(idInvitacion, 2);
          //console.log(idInvitacion);
        } else {
          // console.log("Número de invitación no encontrado");
        }
      }
    }
  };

  function ocultarHeaderFooter() {
    const headerElement = document.querySelector("header");
    if (headerElement) {
      headerElement.style.display = "none";
    }

    const footerElement = document.querySelector("footer");
    if (footerElement) {
      footerElement.style.display = "none";
    }
  }

  const obtInfoSocio = async (idSocio) => {
    let socio = await get("getAllInfoSocio", idSocio);
    if (socio.statuscode === 200) {
      if (socio.file != "") {
        setMostrarLogo(true);
        const datosBinarios = atob(socio.file.fileContents);
        const arrayDeBytes = new Uint8Array(datosBinarios.length);
        for (let i = 0; i < datosBinarios.length; i++) {
          arrayDeBytes[i] = datosBinarios.charCodeAt(i);
        }
        const imagenBlob = new Blob([arrayDeBytes], { type: "image/png" });
        const imagenUrl = URL.createObjectURL(imagenBlob);
        setLogo(imagenUrl);
        setNombreEmpresa(socio.socio[0].nombreEmpresa);
      } else {
        setMostrarLogo(false);
      }
    } else {
      setLogo("");
    }
  };

  function fechaCreacion() {
    let date = new Date();
    date.setHours(date.getHours() - 6);
    let isodate = date.toISOString();
    return isodate;
  }

  let fechCre = fechaCreacion();

  const calcularCredito = (tiempo, valor, rango) => {
    if (tiempo === 0) return false;
    if (valor < 500000) return false;
    if (rango < 10 || rango > 90) return false;

    let fpmVal = fpmValues["P" + tiempo];
    let finaciamientoVal = valor - rango * (valor / 100);
    let enganche = rango * (valor / 100);

    let mensualidad = (finaciamientoVal * fpmVal) / 1000;
    let sueldo = mensualidad / relPagIng;

    setMp(finaciamientoVal);
    setPm(mensualidad);
    setImb(sueldo);
    setTasa(fpmVal);

    actionContext({
      monto: valor,
      enganche: enganche,
      tasa: fpmVal,
      plazoVal: tiempo,
      montoPrestar: finaciamientoVal,
      fechaCrecion: fechCre,
      mensualidad: mensualidad,
    });
    setEsperandoRespuesta(true);
  };

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useMemo(() => {
    if(esperandoRespuesta){
      setTimeout(() => {
        setMsgEspera(true);
      }, 7000);
    }
  }, [esperandoRespuesta])

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtIdSocio();
  }, []);

  useEffect(()=>{
    setEnganche(GetNF(rango * (valor/100)));
  }, [valor])

  return (
    <>
      {/* {mostrarLogo ? (
        <div className="col12 cont_logo Socio_img ">
          <img src={logo} alt="Crear Cuenta Socios" />
          <h1>Calculadora {nombreEmpresa}</h1>
        </div>
      ) : (
        ""
      )} */}
      <div id="mainDivCalculadora" className="cont_flex strech">
        <div className="col6 simulador">
          <div className="precio_cotizar">
            <label>Precio de Inmueble</label>
            <p>
              $
              <input
                className="txtRight"
                type="tel"
                name="txtPrecio"
                id="txtPrecio"
                value={valorComas}
                onChange={(ev) => {
                  setValor(
                    parseInt(
                      ev.target.value.replaceAll(".", "").replaceAll(",", "")
                    )
                  );
                  let sueldo = ev.target.value;
                  sueldo = sueldo.replaceAll(",", "");
                  sueldo = sueldo.replace(" MXN", "");
                  sueldo = sueldo.replace(" MX", "");
                  sueldo = sueldo.replace(" M", "");
                  sueldo = sueldo.replaceAll(" ", "");
                  sueldo = sueldo.replace("$", "");
                  if (sueldo === 0 || sueldo === "") {
                    setValorComas("");
                    setInmueble5(false);
                  } else {
                    if (sueldo >= 500000) {
                      setInmueble5(true);
                    } else {
                      setInmueble5(false);
                    }
                    sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                    if (sueldo !== "NaN") {
                      setValorComas(sueldo);
                    }
                  }
                  calcularCredito(
                    tiempo,
                    parseInt(
                      ev.target.value.replaceAll(".", "").replaceAll(",", "")
                    ),
                    rango
                  );
                }}
              />
              <paso1 precioInmueble={10} />
              <span>MXN</span>
            </p>
            {inmueble5 === false && (
              <small class="span">
                El precio del inmueble debe ser mayor o igual a $500,000 MXM{" "}
              </small>
            )}
          </div>
          <div className="precio_barra">
            <label>
              Enganche
              <Tooltip id="my-tooltip-enganche" />
              <i
                data-tooltip-id="my-tooltip-enganche"
                data-tooltip-content="El monto que cuentas para dar de enganche"
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </label>
            <div className="slidecontainer">
              <input
                type="range"
                min="10"
                max="90"
                value={rango}
                className="slider"
                id="myRange"
                onChange={(ev) => {
                  setRango(ev.target.value);
                  calcularCredito(tiempo, valor, ev.target.value);
                  setEnganche(GetNF(ev.target.value * (valor / 100)));
                  setRangoEngacheValido(true);
                }}
              />
            </div>
            <div className="description">
              <p>
                <span className="porcentaje" id="porcentaje"></span>{round(rango, 2)}%
              </p>

              <div className="enganche_cotizar">
                <p>
                  $
                  <input
                    className="txtRight"
                    type="tel"
                    name="txtEnganche"
                    id="txtEnganche"
                    value={enganche}
                    onFocus={(e)=>{
                      setEnganche(e.target.value.slice(0, -3));
                    }}
                    onChange={(e) => {
                      
                      let _enganche = parseInt(e.target.value.replaceAll(",", ""));
                      if (isNaN(_enganche)){
                        _enganche = 0;
                      }

                      const _rango = _enganche * (100/ valor);
                      
                      setRango(_rango);
                      calcularCredito(tiempo, valor, _rango);

                      if (_enganche === 0) {
                        setEnganche("0");
                        setRangoEngacheValido(false);
                      } else {
                        if (_rango >= 10 && _rango <= 90) {
                          setRangoEngacheValido(true);
                        } else {
                          setRangoEngacheValido(false);
                        }
        
                        _enganche = _enganche.toLocaleString("es-MX");
                        setEnganche(_enganche);
                      }
                    }}
                    onBlur={(e) =>{
                      setEnganche(e.target.value + ".00");
                    }}
                    onKeyDown={(e)=>{
                      if(e.key === "Enter"){
                        setEnganche(e.target.value + ".00");
                        e.currentTarget.blur();
                      }
                    }}
                  />
                  <span>MXN</span>
                </p>
              </div>

            </div>
            {!rangoEngacheValido && (
                <small class="span">
                  El enganche se debe de encontrar entre el 10% y 90%{" "}
                </small>
              )
            }
          
          </div>

          <div className="precio_plazo">
            <label>
              Plazo
              <Tooltip id="my-tooltip-plazo" />
              <i
                data-tooltip-id="my-tooltip-plazo"
                data-tooltip-content="El plazo del crédito"
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </label>
            
            <div className="cont_calculadora_btns">
              <button
                className={`btb_plazo, ${
                  tiempo === 5 ? "btn_plazo_selected" : ""
                }`}
                id="btnPlazo5a"
                value="5"
                onClick={() => {
                  setTiempo(5);
                  calcularCredito(5, valor, rango);
                }}
              >
                <span style={{visibility: 'hidden'}}>0</span>5 años
              </button>
              <button
                className={`btb_plazo, ${
                  tiempo === 10 ? "btn_plazo_selected" : ""
                }`}
                id="btnPlazo10a"
                value="10"
                onClick={() => {
                  setTiempo(10);
                  calcularCredito(10, valor, rango);
                }}
              >
                10 años
              </button>
              <button
                className={`btb_plazo, ${
                  tiempo === 15 ? "btn_plazo_selected" : ""
                }`}
                id="btnPlazo15a"
                value="15"
                onClick={() => {
                  setTiempo(15);
                  calcularCredito(15, valor, rango);
                }}
              >
                15 años
              </button>
              <button
                className={`btb_plazo, ${
                  tiempo === 20 ? "btn_plazo_selected" : ""
                }`}
                id="btnPlazo20a"
                value="20"
                onClick={() => {
                  setTiempo(20);
                  calcularCredito(20, valor, rango);
                }}
              >
                20 años
              </button>
            </div>
            
          </div>
          <div id="confirmarResultadosCalculadoraWeb" className="bg_morado2">
            <h3>¿Te gustaron los resultados? <br /> <br /></h3>
            <div className="manita_cont width100">
              {mp !== "-" &&
                pm !== "-" &&
                imb !== "-" &&
                tasa !== "-" &&
                !cookie.get("session") &&
                inmueble5 == true && rangoEngacheValido === true && (
                  <>
                    <Link className="bg_alternativo btn_morado_personalizado margin0" to="/preRegistroDinamico">
                      ¡Descubre cuanto te presta cada banco y encuentra la mejor tasa!
                    </Link>
                    <img src={gifClick} alt="click" class="manita_click"></img>
                  </>
                )}

              {mp !== "-" &&
                pm !== "-" &&
                imb !== "-" &&
                tasa !== "-" &&
                cookie.get("session") &&
                inmueble5 == true && rangoEngacheValido === true && (
                  <>
                    <Link
                      className="bg_alternativo btn_morado_personalizado"
                      to="/observarRegistro"
                    >
                      ¡Descubre cuanto te presta cada banco y encuentra la mejor tasa!
                    </Link>
                    <img src={gifClick} alt="click" class="manita_click"></img>
                  </>
              )}

            </div>
          </div>
        </div>
        <div className="col6 resultados bg_morado2">
          <h3>Resultados de tu simulación</h3>
          <ul>
            <li>
              Monto a prestar
              <span id="spanFinaciamientoVal">${GetNF(mp)}</span>
              &nbsp;MXN
              <Tooltip id="my-tooltip-montoPrestar" />
              <i
                data-tooltip-id="my-tooltip-montoPrestar"
                data-tooltip-html="Cantidad total que deseas solicitar<br>para tu crédito hipotecario."
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </li>
            <li>
              Pago mensual* <span id="spanMensualidad">${GetNF(pm)}</span>
              &nbsp;MXN
              <Tooltip id="my-tooltip-pagoMensual" />
              <i
                data-tooltip-id="my-tooltip-pagoMensual"
                data-tooltip-html="Cantidad que pagarás cada mes,<br>incluyendo capital e intereses del préstamo."
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </li>
            <li>
              Ingresos mensuales brutos**{" "}
              <span id="spanSueldo">${GetNF(imb)}</span>
              &nbsp;MXN
              <Tooltip id="my-tooltip-brutoMensual" />
              <i
                data-tooltip-id="my-tooltip-brutoMensual"
                data-tooltip-html="Total de ingresos mensuales requeridos antes de<br>impuestos y otras deducciones para solicitar el crédito."
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </li>
            <li>
              Tasa <span id="spanFpmVal">{GetNF(tasa)}</span>%
              <Tooltip id="my-tooltip-porcentajeTasa" />
              <i
                data-tooltip-id="my-tooltip-porcentajeTasa"
                data-tooltip-html="Tasa de interés aplicada a tu crédito hipotecario,<br>puede ser fija o variable."
                className="fa fa-circle-exclamation"
              ></i>
              <Tooltip />
            </li>
            <li className="no_border">
              {
                msgEspera && (
                  <div className="cont_msg border_animation width100">
                    Cuando estés listo da clic en el botón
                  </div>
                )
              }
            </li>
          </ul>
          <small>* Los valores mostrados son un estimado</small>
          <small>
            ** Si tus ingresos son menores, contáctanos y juntos encontraremos
            la solución para ti
          </small>
        </div>
        <div id="confirmarResultadosCalculadoraMovil" className="bg_morado2">
          <h3>¿Te gustaron los resultados?</h3>
          <div className="manita_cont width100">
            {mp !== "-" &&
              pm !== "-" &&
              imb !== "-" &&
              tasa !== "-" &&
              !cookie.get("session") &&
              inmueble5 == true && rangoEngacheValido === true && (
                <>
                  <Link
                    className="bg_alternativo btn_morado_personalizado width80"
                    to="/preRegistroDinamico"
                  >
                    ¡Descubre cuanto te presta cada banco y encuentra la mejor tasa!
                  </Link>
                  <img src={gifClick} alt="click" class="manita_click"></img>
                </>
            )}
            {mp !== "-" &&
            pm !== "-" &&
            imb !== "-" &&
            tasa !== "-" &&
            cookie.get("session") &&
            inmueble5 == true && rangoEngacheValido === true && (
              <>
                <Link
                  className="bg_alternativo btn_morado_personalizado width80"
                  to="/observarRegistro"
                >
                  ¡Descubre cuanto te presta cada banco y encuentra la mejor tasa!
                </Link>
                <img src={gifClick} alt="click" class="manita_click"></img>
              </>
            )}

          </div>
        </div>
      </div>
      <br />
    </>
  );
}
