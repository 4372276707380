import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import "../../App.css";
import "../../sass/style.scss";
import Rectangle from "../../images/Rectangle-3.jpg";
import Swal from "sweetalert2";
import { get, post } from "../../utils/http";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoGeneral() {
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [infoPersonal, setInfoPersonal] = useState("");
  const [infoLaboral, setInfoLaboral] = useState("");
  const [infoMedica, setInfoMedica] = useState("");
  const [infoDocsInicial, setDocsInicial] = useState("");
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idResPrecalifica = await get("getInfoCalifica", cookies.get("id"));
    // console.log(idResPrecalifica);
    setIdResPrecalifica(
      idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
    verificarSecciones(
      idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const verificarSecciones = async (idResPrecalifica) => {
    let dataInfoGeneral = await get(
      "getVerificarSeccionInformacion",
      cookies.get("id") /*idResPrecalifica*/
    );
    //console.log(dataInfoGeneral);
    if (dataInfoGeneral && dataInfoGeneral.statuscode === 200) {
      //* Info personal
      if (dataInfoGeneral.respuestas.infoPersonal) {
        if (dataInfoGeneral.respuestas.coAcreditado == false) {
          setInfoPersonal("informacionGeneralCompletado active");
        }
      }

      //* Info laboral
      if (dataInfoGeneral.respuestas.infoLaboral) {
        if (dataInfoGeneral.respuestas.coAcreditado == false) {
          setInfoLaboral("informacionGeneralCompletado active");
        }
      }

      //* Info medica
      if (dataInfoGeneral.respuestas.infoMedica) {
        if (dataInfoGeneral.respuestas.coAcreditado == false) {
          setInfoMedica("informacionGeneralCompletado active");
        }
      }

      //* Info documentacion inciial
      if (dataInfoGeneral.respuestas.infoDocInicial) {
        if (dataInfoGeneral.respuestas.coAcreditado == false) {
          setDocsInicial("informacionGeneralCompletado active");
          // console.log("general es :" + dataInfoGeneral.respuestas.coAcreditado);
        }
      }

      if (
        dataInfoGeneral.respuestas.infoPersonal &&
        dataInfoGeneral.respuestas.infoLaboral &&
        dataInfoGeneral.respuestas.infoMedica &&
        dataInfoGeneral.respuestas.infoDocInicial
      ) {
        if (dataInfoGeneral.respuestas.coAcreditado == false) {
          setBotonDeshabilitado(false);
        } else {
          setBotonDeshabilitado(true);
        }
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    obtInfoCuestionario();
  }, []);

  //Para evaluar todas las secciones antes de enviar.
  const completarProceso = async () => {
    if (!botonDeshabilitado) {
      //Cuando la informacion ya esta completa

      // * Se envia el correo de informacion completa
      //let data = await post('correoInfoGeneralCompleta', cookies.get('correo'));
      // console.log(cookies.get("id"));
      await get("enviarCorreosInformacionCompleta", cookies.get("id"));
      // * Se envia toda la información a onbase
      let envioDatos = await get("enviarDatosCompletosOnbase", parseInt(cookies.get("id")));
      if(envioDatos.statuscode === 200)
      {
        window.location.href = '/informacionEnviada';
      }else{
        Swal.fire({
          icon: "error",
          title: "Algo salió mal",
          text: "Inténtelo nuevamente más tarde.",
          confirmButtonText: "Aceptar"
        });
      }
    } else {
      Swal.fire({
        title: "Faltan datos",
        text: "Faltan datos por llenar",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/home">
          {"<"} Regresar a mis solicitudes hipotecarias
        </a>

        <div className="dosColumns cont_flex">
          <div className="col6">
            <h1 className="titt">
              Antes de continuar te pediremos la siguiente información
            </h1>
            <p>
              Requerimos de la siguiente información para continuar con tu
              solicitud. En caso de que tengas alguna pregunta o no puedas
              contestar algún campo, no dudes de contactar a nuestros ejecutivos
              a través del icono de cámara en la esquina inferior derecha.
            </p>

            <ul className="check">
              <li className={"linkInfoGeneral " + infoDocsInicial}>
                <Link to="/informacion/inf-DocumentacionInicial">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Documentacion Inicial{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneral " + infoPersonal}>
                <Link to="/informacion/inf-personal">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información personal{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneral " + infoLaboral}>
                <Link to="/frmInfoLaboral">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información laboral{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneral " + infoMedica}>
                <Link to="/frmInfoMedica">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información médica{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>

            <Link
              to="#"
              className="btn btn_gris width_auto"
              onClick={completarProceso}
              disabled={botonDeshabilitado}
            >
              Enviar documentos
            </Link>
          </div>
          <div className="cont_img col6">
            {" "}
            <img src={Rectangle} alt="img-info-general" />
          </div>
        </div>
      </div>
    </>
  );
}
