import "../../../App.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import "../../../sass/style.css";
import { TarjetaSubirDocumentacion } from "../../../components/Cards";
import Swal from "sweetalert2";
import { get, post, postDocs } from "../../../utils/http";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import { Cookies } from "react-cookie";
import { ModalTokenNoValido } from "../../../components/Modals";


// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function VendedorDocumentacionVivienda() {
  const [isLoading, setIsLoading] = useState(true);
  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  const cookies = new Cookies();

  const idResPrecalifica = cookies.get("tokenExp") ? 0 : parseInt(cookies.get("idResPre"));
  const idCuestionario = cookies.get("tokenExp") ? 0 : parseInt(cookies.get("idCuestionario"));
  const [popTokenNoValido, setPopTokenNoValido] = useState(false);

  function ocultarHeaderFooter() {
    const headerElement = document.querySelector("header");
    if (headerElement) {
      headerElement.style.display = "none";
    }

    const headerResponsiveElement = document.querySelector("div.header_responsive");
    if (headerResponsiveElement) {
      headerResponsiveElement.style.display = "none";
    }

    const footerElement = document.querySelector("footer");
    if (footerElement) {
      footerElement.style.display = "none";
    }
  }

  const handleDocumentoChange = async (event, errorFunction = () => {}) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    //console.log("Archivo seleccionado:", selectedFile); // Verifica si muestra el archivo en la consola

    if (inputName == "16" || inputName == "13" || inputName == "8") {
      const selectedFiles = event.target.files;
      if (selectedFiles.length > 0) {
        try {
          
          let insertDocumento;
          for (let i = 0; i < selectedFiles.length; i++) {
            const formData = new FormData();
            formData.append("Archivo", selectedFiles[i]);
            formData.append("IdCatDocumento", inputName);
            formData.append("IdCuestionario", idCuestionario);
            formData.append("IdResPrecalifica", idResPrecalifica);
            insertDocumento = await postDocs("subirDocumento", formData);
          }
          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erróneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    } else {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("Archivo", selectedFile);
        formData.append("IdCatDocumento", inputName);
        formData.append("IdCuestionario", idCuestionario);
        formData.append("IdResPrecalifica", idResPrecalifica);
        try {
          let insertDocumento = await postDocs("subirDocumento", formData);

          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
            //navigate("/solicitudAceptada");
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erroneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    }
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idCatDocumento) => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionario),
      idCatDocumento: idCatDocumento,
    };

    let verificarDocumento;
    if (idCatDocumento == "16" || idCatDocumento == "13" || idCatDocumento == "8"){
      verificarDocumento = await post("getInfoDocumentos", params);
    }else{
      verificarDocumento = await post("getInfoDocumento", params);
    }
    
    if (verificarDocumento.statuscode === 200) {

      if (idCatDocumento == "16" || idCatDocumento == "13" || idCatDocumento == "8"){

        const docs = verificarDocumento.value.map((doc) => {
          return{
            ...doc,
            archivo: doc.archivo.split("/").pop()
          }
        })
        //console.log(docs)
        return {
          documentos: docs,
          info: verificarDocumento.info,
        };
      }else{
        let cadena = verificarDocumento.value.archivo;
        // Dividir la cadena por las barras invertidas para obtener partes
        const partes = cadena.split("/");
  
        // Obtener la última parte que contiene el nombre de archivo y extensión
        const nombreArchivoConExtension = partes[partes.length - 1];
  
        return {
          nombreDoc: nombreArchivoConExtension,
          info: verificarDocumento.info,
        };
      }
    } else {
      return verificarDocumento.info;
    }
  };

  //Para obtener todos los documentos
  var docs;
  const obtDocumentos = async () => {
    let data = await get("getDocumentosByPantalla", "3");
    if (data.statuscode === 200) {
      let documentos = [];
      let arrDocsCat = data.documentos[0].documentos;
      // Crear un array de promesas para verificar los documentos
      const verificarPromises = arrDocsCat.map(async (documento) => {
        try {
          let resVerDoc = await verificarDocumento(documento.idCatDocumentos);
          if(documento.idCatDocumentos  == "16" || documento.idCatDocumentos == "13" || documento.idCatDocumentos == "8"){
            return {
              titulo: documento.nombre,
              onDocumentoChange: { handleDocumentoChange },
              tipoDoc: documento.idCatDocumentos,
              docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
              documentos: resVerDoc.documentos
            };
          }else{
            return {
              titulo: documento.nombre,
              parrafo:
                documento.idCatDocumentos === "3" ||
                documento.idCatDocumentos === "4"
                  ? "Nota de recibo"
                  : "",
              link: documento.idCatDocumentos !== "2" ? false : true,
              enlace:
                documento.idCatDocumentos !== "2"
                  ? ""
                  : "https://saturn5.mx/contrato-ejemplo.docx",
              onDocumentoChange: { handleDocumentoChange },
              tipoDoc: documento.idCatDocumentos,
              docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
              nombreDocumento: resVerDoc.nombreDoc,
            };
          }
          
        } catch (error) {
          console.error("Error al verificar el documento", error);
          return null;
        }
      });

      // Esperar a que todas las promesas se completen
      const documentosWithVerificaciones = await Promise.all(verificarPromises);

      // Filtrar los resultados nulos en caso de errores
      const documentosValidos = documentosWithVerificaciones.filter(
        (doc) => doc !== null
      );

      if (documentosWithVerificaciones.length === 0) {
        console.log("No hay documentos");
      } else {
        //console.log(documentosWithVerificaciones);
        docs = documentosWithVerificaciones.map((documento) => {
          
            return (
              <TarjetaSubirDocumentacion
                titulo={documento.titulo}
                parrafo={documento.parrafo}
                link={documento.link}
                enlace={documento.enlace}
                onDocumentoChange={handleDocumentoChange}
                tipoDoc={documento.tipoDoc}
                del={true}
                documentoSubido={documento.docVerificado}
                notas={
                  documento.titulo === "Contrato de compraventa"
                    ? "Asegura que este firmado, vigente, legible y asegura que tenga la información correcta de la propiedad a adquirir."
                    : documento.titulo === "Recibo de Pago de Agua"
                    ? "Asegura que sea legible, y sea reciente."
                    : documento.titulo === "Recibo de Pago de Predial"
                    ? "Asegura que sea legible y reciente."
                    : documento.titulo === "Titulo de propiedad"
                    ? "Asegura que el documento este completo, sea legible y cuente con la hoja de inscripción al registro público de la propiedad."
                    : documento.titulo === "Otros documentos"
                    ? "Asegura que sean legibles y vigentes."
                    : "nota de reserva"
                }
                nombreDocumento={documento.nombreDocumento}
                documentos={documento.documentos}
              />
            );
          
        });

        const root = ReactDOM.createRoot(
          document.getElementById("documentosRender")
        );

        root.render(docs);
      }
    }
  };

  function verificarToken(){
    if(cookies.get("tokenExp")){
      setPopTokenNoValido(true);
    }else{
      setPopTokenNoValido(false);
    }
  }

  const actualizarToken = async () =>{
    const res = await post("actualizarTokenInvitacionVendedor", {
      token: cookies.get("token"),
      idUsuario: cookies.get("idUsuario"),
      idResPrecalifica: cookies.get("idResPre"),
      idCuestionario: cookies.get("idCuestionario"),
    });

    if (res.statuscode === 200){
      Swal.fire({
        title: "Enlace actualizado",
        text: "Revise su correo",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    }else{
      Swal.fire({
        title: "Error al actualizar enlace",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        verificarToken();
        ocultarHeaderFooter();
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtDocumentos();
  }, []);

  useEffect(()=>{
    if (recargarDocumentos){
      obtDocumentos();
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className="">

        {
          popTokenNoValido && (
            <ModalTokenNoValido actualizarToken={actualizarToken}/>
          )
        }

        <Link to="/documentosViviendaVendedor" className="link linkGrande back">
          {" "}
          {"<"} Regresar a información vivienda
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Documentaci&oacute;n vivienda</h1>
        <p>
          Debes de poner los documentos de la vivienda que deseas comprar, la
          persona que te est&aacute; vendiendo la vivienda debe de darte esta
          informaci&oacute;n.
        </p>

        <hr />
        <br />

        <div className="cont_flex con_flex_wrap responsivoColumn" id="documentosRender"></div>

        <br />
        <Link to="/documentosViviendaVendedor" className="btn ">
          Regresar
        </Link>
        <Link to="/documentosViviendaVendedor" className="btn btn_morado">
          Guardar
        </Link>

        <p>Si realizaste un cambio recuerda guardar antes de salir.</p>
      </div>
    </>
  );
}
